import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";
import { useSelector } from "react-redux";
import UserLogin from "./pages/user/Login";
import UserDashboard from "./pages/user/Dashboard";
import UserAccount from "./pages/user/Account";
import UserProfile from "./pages/user/Profile";
import UserDevice from "./pages/user/Device";
import UserLog from "./pages/user/Log";
import UserLayout from "./pages/layout/UserLayout";
import AdminLogin from "./pages/admin/AdminLogin";
import AdminLayout from "./pages/layout/AdminLayout";
import AdminDashboard from "./pages/admin/AdminDashboard";
import AdminUser from "./pages/admin/AdminDevice";
import AdminCompany from "./pages/admin/AdminCompany";
import AdminReport from "./pages/admin/AdminReport";
import AdminOta from "./pages/admin/AdminOta";
import NoPageFound from "./pages/user/NoPageFound";
import AdminDeviceDetails from "./pages/admin/AdminDeviceDetails";
import AdminCompanyDetails from "./pages/admin/AdminCompanyDetails";

function router() {
  return (
    <div>
      <Router>
        {/* <Header />
        <ContactsCrudContextProvider> */}
        <Routes>
          <Route
            path="/login"
            exact
            element={
              <UserUnprotectedRoute redirectPath="/dashboard">
                <UserLogin />
              </UserUnprotectedRoute>
            }
          />

          {/*<Route
            exact
            path="/"
            element={() =>
              false ? <Navigate to="/" /> : <Navigate to="/login" />
            }
          /> */}
          {/* <Route path="/" exact element={<UserLayout />}>
            <Route index element={<UserDashboard />} />
            <Route path="dashboard" exact element={<UserDashboard />} />
            <Route path="user" exact element={<UserAccount />} />
            <Route path="profile" exact element={<UserProfile />} />
            <Route path="*" element={<NoPageFound />} />
          </Route> */}

          <Route
            path="/"
            exact
            element={
              <UserProtectedRoute redirectPath="/login">
                <UserLayout />
              </UserProtectedRoute>
            }
          >
            <Route index element={<UserDashboard />} />
            <Route path="dashboard" exact element={<UserDashboard />} />
            <Route path="user" exact element={<UserAccount />} />
            <Route path="profile" exact element={<UserProfile />} />
            <Route path="device" exact element={<UserDevice />} />
            <Route path="report" exact element={<UserLog />} />
            <Route path="*" element={<NoPageFound />} />
          </Route>

          <Route path="admin" exact element={<AdminAuthCheckRoute />}>
            <Route index element={<AdminDashboard />} />
            <Route path="dashboard" element={<AdminDashboard />} />
            <Route path="device" element={<AdminUser />} />
            <Route path="company" element={<AdminCompany />} />
            <Route path="report" exact element={<AdminReport />} />
            <Route path="ota" exact element={<AdminOta />} />
            <Route
              path="devicedetails/:deviceId"
              exact
              element={<AdminDeviceDetails />}
            />
            <Route
              path="comanydetails/:companyId"
              exact
              element={<AdminCompanyDetails />}
            />
          </Route>

          {/* <Route
            path="/signup"
            exact
            element={
              <ProtectedRoute redirectPath="/" isAllowed={true}>
                <Login />{" "}
              </ProtectedRoute>
            }
          /> */}
        </Routes>
        {/* </ContactsCrudContextProvider> */}
      </Router>
    </div>
  );
}

// Protected Route
const UserProtectedRoute = ({ redirectPath = "/login", children }) => {
  const user = useSelector((state) => state.user);

  if (!user) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
};

// Unprotected Route - For Login
const UserUnprotectedRoute = ({ redirectPath = "/dashboard", children }) => {
  const user = useSelector((state) => state.user);

  if (typeof user !== "undefined" && user) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
};

// const UserAuthCheckRoute = () => {
//   return false ? <UserLayout /> : <UserLogin />;
// };

const AdminAuthCheckRoute = () => {
  const auth = JSON.parse(localStorage.getItem("auth"));
  return auth ? <AdminLayout /> : <AdminLogin />;
};

export default router;
