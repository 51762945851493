import Axios from "../utils/axios";

const checkTokenAndHeaders = () => {
  const configHeaders = {
    "content-type": "application/json",
    Accept: "application/json",
  };
  let userLoggedToken = JSON.parse(localStorage.getItem("persist:root"));

  userLoggedToken = JSON.parse(userLoggedToken.token);
  if (userLoggedToken) {
    configHeaders["Authorization"] = `Bearer ${userLoggedToken}`;
  }
  return configHeaders;
};

const adminHeaders = () => {
  const configAdminHeaders = {
    "content-type": "application/json",
    Accept: "application/json",
  };

  return configAdminHeaders;
};

const apiService = {
  /**
   * CALLED WITHOUT ACCESS TOKEN
   */
  async userLogin(payload) {
    return await Axios.get("users/appLogin", {
      headers: {
        Authorization: "Basic " + btoa(payload.email + ":" + payload.password),
      },
    });
  },

  async getDevice() {
    return await Axios.get("device/getDevicesWithUser", {
      headers: checkTokenAndHeaders(),
    });
  },

  async getDevicesByCompanyId() {
    return await Axios.get("device/get", {
      headers: checkTokenAndHeaders(),
    });
  },

  async getLog() {
    return await Axios.get("log/get", {
      headers: checkTokenAndHeaders(),
    });
  },

  async logByFilter(payload) {
    return await Axios.post("log/byFilterV2", payload, {
      headers: checkTokenAndHeaders(),
    });
  },

  //for log graph

  async logByFilterGraph(payload) {
    return await Axios.post("log/byFilterGraphV2", payload, {
      headers: checkTokenAndHeaders(),
    });
  },

  async logByFilterSummary(payload) {
    return await Axios.post("log/byFilterSummary", payload, {
      headers: checkTokenAndHeaders(),
    });
  },

  async getUsers() {
    return await Axios.get("users/getUsers", {
      headers: checkTokenAndHeaders(),
    });
  },

  async addUser(payload) {
    return await Axios.post("users/create", payload, {
      headers: checkTokenAndHeaders(),
    });
  },

  async updateUser(userId, payload) {
    return await Axios.patch(`users/update/${userId}`, payload, {
      headers: checkTokenAndHeaders(),
    });
  },

  // async updateDevice(deviceId, payload) {
  //   return await Axios.patch(`device/update/${deviceId}`, payload, {
  //     headers: checkTokenAndHeaders(),
  //   });
  // },

  async updateCompany(companyId, payload) {
    return await Axios.patch(`company/update/${companyId}`, payload, {
      headers: checkTokenAndHeaders(),
    });
  },
  async deviceUserRemove(payload) {
    return await Axios.post("deviceThroughUser/remove", payload, {
      headers: checkTokenAndHeaders(),
    });
  },
  async createOta(version, description, fileLink) {
    return await Axios.post(
      "otaUpdate/create",
      {
        version: version,
        description: description,
        fileLink: fileLink,
        OS: "android",
        token: process.env.REACT_APP_ADMIN_TOKEN,
      },
      {
        headers: adminHeaders(),
      }
    );
  },
  async updateOta(otaUpdateId, version, description, fileLink) {
    return await Axios.post(
      "otaUpdate/update",
      {
        otaUpdateId: otaUpdateId,
        version: version,
        description: description,
        fileLink: fileLink,
        OS: "android",
        token: process.env.REACT_APP_ADMIN_TOKEN,
      },
      {
        headers: adminHeaders(),
      }
    );
  },
  async getAllOtas() {
    return await Axios.post(
      "otaUpdate/getAllOtaUpdateList",
      { token: process.env.REACT_APP_ADMIN_TOKEN },
      {
        headers: adminHeaders(),
      }
    );
  },
  async deleteOta(otaId) {
    return await Axios.post(
      "otaUpdate/delete",
      { token: process.env.REACT_APP_ADMIN_TOKEN, otaId },
      {
        headers: adminHeaders(),
      }
    );
  },

  //adding device
  async addDevice(payload) {
    payload.token = process.env.REACT_APP_ADMIN_TOKEN;
    return await Axios.post("admin/createDevice", payload, {
      headers: adminHeaders(),
    });
  },
  //delete device

  async deleteDevice(payload) {
    payload.token = process.env.REACT_APP_ADMIN_TOKEN;
    return await Axios.post("admin/deleteDevice", payload, {
      headers: adminHeaders(),
    });
  },
  //update device
  async updateDevice(payload) {
    payload.token = process.env.REACT_APP_ADMIN_TOKEN;
    return await Axios.post("admin/updateDevice", payload, {
      headers: adminHeaders(),
    });
  },

  //delete company
  async deleteCompany(payload) {
    payload.token = process.env.REACT_APP_ADMIN_TOKEN;
    return await Axios.post("admin/deleteCompany", payload, {
      headers: adminHeaders(),
    });
  },

  //setExpiryDate

  async updateDate(payload) {
    payload.token = process.env.REACT_APP_ADMIN_TOKEN;
    return await Axios.post("admin/setExpiry", payload, {
      headers: adminHeaders(),
    });
  },

  //get admin device list
  async getAdminDevice() {
    return await Axios.post(
      "admin/getAllDevices",
      { token: process.env.REACT_APP_ADMIN_TOKEN },
      {
        headers: adminHeaders(),
      }
    );
  },

  async getDeviceById(deviceId) {
    // console.log("deviceId", deviceId);
    return await Axios.post(
      "admin/getDeviceById",
      { token: process.env.REACT_APP_ADMIN_TOKEN, deviceId: deviceId },
      {
        headers: adminHeaders(),
      }
    );
  },

  async getCompanyByID(companyId) {
    console.log("companyId", companyId);
    return await Axios.post(
      "admin/getCompanyWithDevices",
      { token: process.env.REACT_APP_ADMIN_TOKEN, companyId: companyId },
      {
        headers: adminHeaders(),
      }
    );
  },

  //fetch company details

  async getCompanyByAdmin() {
    return await Axios.post(
      "admin/getAllCompany",
      { token: process.env.REACT_APP_ADMIN_TOKEN },
      {
        headers: adminHeaders(),
      }
    );
  },
  //create company by admin

  async addCompanyByAdmin(payload) {
    // { token: process.env.REACT_APP_ADMIN_TOKEN },
    payload.token = process.env.REACT_APP_ADMIN_TOKEN;
    return await Axios.post("admin/createCompany", payload, {
      headers: adminHeaders(),
    });
  },

  async disableDeviceByAdmin(payload) {
    payload.token = process.env.REACT_APP_ADMIN_TOKEN;
    return await Axios.post("admin/deviceCompanyMisassign", payload, {
      headers: adminHeaders(),
    });
  },

  async companyassignByAdmin(payload) {
    payload.token = process.env.REACT_APP_ADMIN_TOKEN;
    return await Axios.post("admin/deviceCompanyAssign", payload, {
      headers: adminHeaders(),
    });
  },

  // async getUsers(payload) {
  //   return await Axios.get("users/getUsers", {
  //     headers: {
  //       Authorization:
  //         "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb21wYW55SWQiOiI2MzkwNTNhNDU2MDg0ZGY2NjZlNDljZDQiLCJ1c2VySWQiOiI2Mzk2YjcyYmI1MmU1ZTIzNDliYzJiMWMiLCJpYXQiOjE2NzA4NDcyMDQsImV4cCI6MTY3MzQzOTIwNH0.o3kfmb74SN056chOG26e3inKzbpqlrGfGpylZ4A4O0w",
  //     },
  //   });
  // },
};

export default apiService;
