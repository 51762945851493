import { ActionTypes } from "../constants/action-types";

const initialState = {
  // user: null,
  // token: null,
  company: null,
};

export const companyReducer = (state = null, { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_COMPANY:
      return { ...state , ...payload };
    case ActionTypes.GET_COMPANY:
      return { ...state, ...payload };
    case ActionTypes.REMOVE_COMPANY:
      return null;
    default:
      return state;
  }
};
