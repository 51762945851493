import { ActionTypes } from "../constants/action-types";
// import axiosClient from "../../api/index";

export const setUser = (user) => {
  return {
    type: ActionTypes.SET_USER,
    payload: user,
  };
};

export const removeUser = () => {
  return {
    type: ActionTypes.REMOVE_USER,
  };
};

export const setToken = (token) => {
  return {
    type: ActionTypes.SET_TOKEN,
    payload: token,
  };
};

export const setCompany = (company) => {
  return {
    type: ActionTypes.SET_COMPANY,
    payload: company,
  };
};
