import React, { useEffect, useState } from "react";
import { Grid, Paper, Typography } from "@mui/material";

import Chart from "../../components/userDahboard/Chart";
import apiService from "../../api";
import moment from "moment/moment";

function DashboardContent() {
  const [startDate, setStartDate] = useState(
    moment().subtract(6, "days").format()
  );

  const [endDate, setEndDate] = useState(moment().format());
  const [graphData, setGraphData] = useState([]);
  const [summaryData, setSummaryData] = useState([
    {
      _id: "RECHARGE",
      total: 0,
    },
    {
      _id: "START",
      total: 0,
    },
  ]);

  React.useEffect(() => {
    pageInit();
  }, []);

  const pageInit = () => {
    setTimeout(() => {
      Promise.all([logByFilterSummary(), getLogGraphByFilter()]);
    }, 100);
  };

  const logByFilterSummary = async () => {
    const response = await apiService.logByFilterSummary({
      startDate: startDate,
      endDate: endDate,
    });

    if (response.data.data.length > 0) {
      setSummaryData(response.data.data);
    }
  };
  const getLogGraphByFilter = async () => {
    const response = await apiService.logByFilterGraph({
      startDate: startDate,
      endDate: endDate,
    });

    setGraphData(response.data.data);
  };

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      style={{ minHeight: "100vh" }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8} md={8} lg={8}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  height: 300,
                }}
                elevation={2}
              >
                <Chart
                  graphData={graphData}
                  startDate={startDate}
                  endDate={endDate}
                />
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: 300,
            }}
            elevation={2}
          >
            {summaryData.map((d, index) => (
              <div key={index}>
                <Typography variant="h4" gutterBottom>
                  {d._id === "START" ? "INHALE" : d._id}
                </Typography>
                <Typography variant="h6" gutterBottom>
                  {Math.floor(Number(d.total) / 60)} Min [ {d.total} Sec ]
                </Typography>
              </div>
            ))}
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default function Dashboard() {
  return <DashboardContent />;
}
