import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { styled } from "@mui/material/styles";
// import Grid from "@mui/material/Unstable_Grid2";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Stack } from "@mui/material";
import moment from "moment/moment.js";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function ExpiryDateUpdate({
  modalOpen,
  isEdit,
  editDevice,
  editDate,
  modalClose,
}) {
  //   const [name, setName] = useState("");
  //   const [macAddress, setMac] = useState("");

  const [expiryDate, setExpiryDate] = useState();

  const dialogClose = () => {
    clearFields();
    modalClose();
  };

  const clearFields = () => {
    // setName("");
    // setMac("");
  };

  const updateDevice = (e) => {
    e.preventDefault();

    console.log("expiredate", expiryDate);
    modalClose({
      isEdit,
      payload: {
        deviceId: editDevice._id,
        expiryDate: expiryDate,
      },
    });

    //}
    clearFields();
    // }
  };

  useEffect(() => {
    if (isEdit) {
      console.log("editDevice", editDevice.expiryDate);
      setExpiryDate(editDevice.expiryDate);
      //setMac(editDevice.macAddress);
    }
  }, [isEdit]);
  return (
    <div>
      <Dialog open={modalOpen}>
        <DialogTitle>{isEdit ? "Edit" : "Add"} Date</DialogTitle>
        <DialogContent style={{ width: "450px" }}>
          <Box
            sx={{
              // display: "flex",
              flexDirection: "column",
              alignItems: "start",
            }}
          >
            <br />
            <Box>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <Stack spacing={3}>
                  <DatePicker
                    label="Expiry Date"
                    openTo="year"
                    views={["day"]}
                    inputFormat="DD/MM/YYYY"
                    value={expiryDate}
                    onChange={(newValue) => {
                      setExpiryDate(moment(newValue).format());
                    }}
                    renderInput={(params) => (
                      <TextField {...params} helperText={null} />
                    )}
                  />
                </Stack>
              </LocalizationProvider>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={dialogClose}>Cancel</Button>
          <Button onClick={updateDevice}>{isEdit ? "Update" : "Create"}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
