import * as React from "react";
import { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  Divider,
  Chip,
  Stack,
  Tooltip,
  Skeleton,
  Paper,
  InputBase,
  IconButton,
  InputAdornment,
} from "@mui/material";
import PageBreadcrumb from "../../components/PageBreadcrumb";
import apiService from "../../api/index";
import moment from "moment/moment.js";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ClearIcon from "@mui/icons-material/Clear";
import CircleIcon from "@mui/icons-material/Circle";
// import IconButton from '@mui/material/IconButton';
import SearchIcon from "@mui/icons-material/Search";

export default function StickyHeadTable() {
  const [page, setPage] = useState(0);
  const [devices, setDevices] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [preLoader, setPreLoader] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [filterDevices, setFilterDevices] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // const [state, setState] = useState({
  //   online: true,
  //   offline: false,
  // });

  // const handleChange = (event) => {
  //   setState({
  //     ...state,
  //     [event.target.name]: event.target.checked,
  //   });
  // };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const deleteUserDeviceRelation = async (deviceId, userId) => {
    setPreLoader(true);
    try {
      await Promise.all([
        apiService.deviceUserRemove({ deviceId: deviceId, userId: userId }),
        getDevice(),
      ]);

      setPreLoader(false);
    } catch (err) {
      setPreLoader(false);
      return Swal.fire({
        title: <strong>Error!</strong>,
        html: <p>{err.response.data.message}</p>,
        icon: "error",
      });
    }
  };

  const getDevice = async () => {
    setPreLoader(true);
    const response = await apiService.getDevice();
    setDevices(response.data.data);
    setFilterDevices(response.data.data);
    setPreLoader(false);
  };

  const searchDevice = (e) => {
    e.preventDefault();
    const searchText = e.target.value;
    setSearchText(searchText);
    setFilterDevices(
      devices.filter((d) =>
        d.name.toLowerCase().includes(searchText.toLowerCase())
      )
    );
  };

  const clearSearch = () => {
    setSearchText("");
    setFilterDevices(devices);
  };

  useEffect(() => {
    getDevice();
  }, []);

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      style={{ minHeight: "100vh" }}
    >
      <PageBreadcrumb pageName="Device" />
      <Grid
        sx={{ padding: "10px 0px 0px 0px" }}
        container
        spacing={2}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        <Grid item xs={12}>
          <Typography variant="h5" component="div">
            Device List
          </Typography>
          <Typography sx={{ fontSize: 14 }} variant="body2" gutterBottom>
            {moment(Date.now()).format("llll")}
          </Typography>
        </Grid>
        {/* <Grid item xs={4}>
          <div style={{ display: "flex" }}>
            <Button
              style={{ marginLeft: "auto" }}
              variant="contained"
              disableElevation
            >
              Create Device
            </Button>
          </div>
        </Grid> */}
      </Grid>

      {/* {devices.length > 0 ? (
        <>
          <div style={{ border: "ridge" }}>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                // style={{ width: "100px" }}
                placeholder="Search"
                inputProps={{ "aria-label": "search" }}
              />
            </Search>
          </div>
          <br />
        </>
      ) : (
        <></>
      )} */}
      <br />
      <Paper
        component="form"
        sx={{
          p: "2px 4px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Search Devices"
          inputProps={{ "aria-label": "search device" }}
          value={searchText}
          onChange={(e) => {
            searchDevice(e);
          }}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
        />
        <IconButton
          type="button"
          sx={{
            p: "10px",
            visibility: searchText.length > 0 ? "visible" : "hidden",
          }}
          aria-label="search"
          onClick={clearSearch}
        >
          <ClearIcon />
        </IconButton>
      </Paper>
      <br />

      {(() => {
        if (preLoader) {
          return (
            <>
              <>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid
                    container
                    spacing={{ xs: 2, md: 3 }}
                    columns={{ xs: 4, sm: 8, md: 12 }}
                  >
                    {[...Array(6)].map((_, index) => (
                      <Grid item xs={12} sm={4} md={4} lg={4} key={index}>
                        <Card
                          sx={{
                            minWidth: 175,
                            display: "block",
                            transitionDuration: "0.3s",
                            height: 250,
                          }}
                        >
                          <CardContent>
                            <Grid container spacing={2} columns={16}>
                              <Grid item xs={8}>
                                <Skeleton
                                  variant="h3"
                                  animation="wave"
                                  sx={{ width: "100%", height: "30px" }}
                                />
                              </Grid>
                              <Grid
                                item
                                xs={8}
                                justifyContent="flex-end"
                                display={"flex"}
                              >
                                <Skeleton
                                  variant="circular"
                                  width={20}
                                  height={20}
                                  animation="wave"
                                />
                              </Grid>
                            </Grid>
                            <br />
                            <Skeleton
                              variant="h3"
                              animation="wave"
                              sx={{ width: "100%", height: "20px" }}
                            />
                            <br />
                            <Skeleton
                              variant="h3"
                              animation="wave"
                              sx={{ width: "100%", height: "20px" }}
                            />
                            <br />

                            <Grid item xs={12} display={"flex"}>
                              {[...Array(3)].map((_, index) => (
                                <Skeleton
                                  variant="rounded"
                                  animation="wave"
                                  width={80}
                                  height={30}
                                  sx={{ margin: "5px" }}
                                  key={index}
                                />
                              ))}
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </>
            </>
          );
        } else if (!preLoader && filterDevices.length > 0) {
          return (
            <>
              <Box sx={{ flexGrow: 1 }}>
                <Grid
                  container
                  spacing={{ xs: 2, md: 3 }}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                >
                  {filterDevices.map((device, index) => (
                    <Grid item xs={12} sm={4} md={4} lg={4} key={index}>
                      <Card
                        sx={{
                          minWidth: 175,
                          display: "block",
                          transitionDuration: "0.3s",
                          height: 250,
                        }}
                      >
                        <CardContent>
                          <Grid container spacing={2} columns={16}>
                            <Grid item xs={8}>
                              <Typography variant="h5" component="div">
                                {device.name}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={8}
                              justifyContent="flex-end"
                              display={"flex"}
                            >
                              {device.disabled ? (
                                <Typography
                                  sx={{ mb: 1.5 }}
                                  color="text.secondary"
                                  style={{ textAlign: "end" }}
                                >
                                  {/* offline */}
                                  <Tooltip title="Disable">
                                    <CircleIcon color="error" />
                                  </Tooltip>
                                </Typography>
                              ) : (
                                <Typography
                                  sx={{ mb: 1.5 }}
                                  color="text.secondary"
                                  style={{ alignItems: "end" }}
                                >
                                  {/* online */}
                                  <Tooltip title="Live">
                                    <CircleIcon color="success" />
                                  </Tooltip>
                                </Typography>
                              )}
                            </Grid>
                          </Grid>
                          {/* <Typography variant="h5" component="div">
                            {device.name}
                          </Typography> */}
                          {/* <p style={{ textAlign: "end" }}>{device.name}</p> */}
                          <Typography
                            sx={{ fontSize: 14 }}
                            variant="body2"
                            gutterBottom
                          >
                            Created At:{" "}
                            {moment(device.createdAt).format("DD-MM-YYYY")}
                          </Typography>
                          {/* {device.disabled ? (
                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                              Status:offline
                            </Typography>
                          ) : (
                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                              Status:online
                            </Typography>
                          )} */}
                          {/* {device.deleted ? (
                            <Typography sx={{ mb: 1.5 }} variant="body2">
                              Device Deleted
                            </Typography>
                          ) : (
                            <Typography sx={{ mb: 1.5 }} variant="body2">
                              Device Activate
                            </Typography>
                          )} */}
                          <Typography variant="body2">
                            MAC:{device.macAddress}
                          </Typography>
                          {device.users.length > 0 ? (
                            <>
                              <br />
                              <Divider />
                              <Stack
                                direction="row"
                                spacing={1}
                                sx={{ marginTop: "10px" }}
                              >
                                <Grid container spacing={0} direction="row">
                                  {device.users.map((user, index) => (
                                    <Chip
                                      key={index}
                                      label={user.name}
                                      variant="outlined"
                                      onDelete={() =>
                                        deleteUserDeviceRelation(
                                          device._id,
                                          user._id
                                        )
                                      }
                                      sx={{ margin: "5px 5px 0px 0px" }}
                                    />
                                  ))}
                                </Grid>
                              </Stack>
                            </>
                          ) : (
                            <></>
                          )}
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </>
          );
        } else {
          return (
            <>
              <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
                style={{ minHeight: "100vh" }}
              >
                <Grid item xs={3}>
                  <Box
                    component="img"
                    src={require("../../assets/NoData3.png")}
                    alt="logo"
                    sx={{
                      width: "300px",
                      height: "auto",
                    }}
                  />
                </Grid>
                <Typography variant="h5" component="div">
                  No Device Found
                </Typography>
              </Grid>
            </>
          );
        }
      })()}
    </Grid>
  );
}
