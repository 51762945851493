import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
// import Typography from "@mui/material/Typography";
import countryList from "../../utils/country";
import { MuiTelInput } from "mui-tel-input";
// import Divider from "@mui/material/Divider";

export default function AdminCompanyCrudForm({
  modalOpen,
  isEdit,
  editUser,
  modalClose,
}) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // const [role, setRole] = useState("user");
  const [country, setCountry] = useState("IND");
  const [phone, setPhone] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  // const [state, setState] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [isOrganization, setIsOrganization] = useState(true);
  const [nameErrorText, setNameErrorText] = useState("");
  const [companyNameErrorText, setcompanyNameErrorText] = useState("");
  const [emailErrorText, setEmailErrorText] = useState("");
  const [phoneErrorText, setPhoneErrorText] = useState("");
  const [passwordErrorText, setPasswordErrorText] = useState("");

  //   {
  //     "companyName": "xyz", --
  //     "email": "xyz@12.com", --
  //     "password": "password", --
  //     "role": "admin",
  //     "phone": "+918920145204",
  //     "address": {
  //         "street": "1 southern point abc",
  //         "city": "Kolkata",
  //         "state": "West Bengal",
  //         "pinCode": "7000156",
  //         "landMark": "edf"
  //     },
  //     "isOrganization": false,
  //     "country": "India"
  // }

  const phoneChange = (newValue) => {
    setPhone(newValue);
  };

  const dialogClose = () => {
    clearFields();
    modalClose();
  };

  const clearFields = () => {
    setName("");
    setCompanyName("");
    setEmail("");
    setPassword("");
    setCountry("IND");
    setPhone("");
    setPinCode("");
    setCity("");
    setStreet("");
    setIsOrganization(true);
    setPasswordErrorText("");
    setPhoneErrorText("");
    setEmailErrorText("");
    setcompanyNameErrorText("");
    setNameErrorText("");
  };

  const updateCompany = (e) => {
    e.preventDefault();

    // console.log("UpdateCom");

    isOrganization && companyName === ""
      ? setcompanyNameErrorText("Please enter company name")
      : setcompanyNameErrorText("");
    // console.log("cname", /[\W_]/g.test(companyName), companyName);

    // /[\W_]/g.test(companyName)
    //   ? setcompanyNameErrorText("Please enter a valid company name")
    //   : setcompanyNameErrorText("");
    // console.log("hihhihi", setcompanyNameErrorText);
    // if (/[\W_]/g.test(companyName)) {
    //   console.log("abcd");
    //   setcompanyNameErrorText("Please enter a valid company name");
    // } else {
    //   console.log("1234");
    //   setcompanyNameErrorText("");
    // }

    name === "" ? setNameErrorText("Please enter name") : setNameErrorText("");

    if (
      email === "" &&
      !/^[a-z0-9][a-z0-9-_.]+@([a-z]|[a-z0-9]?[a-z0-9-]+[a-z0-9]).[a-z0-9]{2,10}(?:.[a-z]{2,10})?$/.test(
        email
      )
    ) {
      setEmailErrorText("Please enter valid email");
    } else {
      setEmailErrorText("");
    }

    phone === ""
      ? setPhoneErrorText("Please enter phone")
      : setPhoneErrorText("");

    !isEdit && password === ""
      ? setPasswordErrorText("Please enter password")
      : setPasswordErrorText("");

    if (
      name !== "" &&
      email !== "" &&
      phone !== "" &&
      password !== "" &&
      companyName !== "" &&
      companyNameErrorText === ""
    ) {
      // if (isEdit) {
      //   // Edit User
      //   modalClose({
      //     isEdit,
      //     payload: {
      //       userId: editUser._id,
      //       name: name,
      //       email: email,
      //       phone: phone,
      //       country: country,
      //     },
      //   });
      // } else {
      // if (isOrganization && companyName !== "") {
      // Add Company
      modalClose({
        isEdit,
        payload: {
          companyName: companyName,
          name: name,
          email: email,
          password: password,
          phone: phone,
          country: country,
          isOrganization: isOrganization,
          role: "admin",
          address: {
            street: street,
            city: city,
            pinCode: pinCode,
            address: street,
            state: "",
            lanMark: "",
          },
        },
      });
      // }
      // }
      clearFields();
    }
  };

  useEffect(() => {}, []);

  // useEffect(() => {
  //   if (isEdit) {
  //     console.log("", editUser);
  //     setName(editUser.name);
  //     setEmail(editUser.email);
  //     setPhone(editUser.phone);
  //     setCountry(
  //       typeof editUser.country === "undefined" ? "IND" : editUser.country
  //     );
  //   }
  // }, [isEdit]);

  return (
    <div>
      <Dialog open={modalOpen}>
        <DialogTitle>{isEdit ? "Edit" : "Add"} Company</DialogTitle>
        <DialogContent style={{ width: "450px" }}>
          <Box
            sx={{
              // display: "flex",
              flexDirection: "column",
              alignItems: "start",
            }}
          >
            <Box component="form" noValidate>
              <FormControl
                sx={{ width: "100%", paddingLeft: "0px;", marginTop: "10px;" }}
              >
                <InputLabel id="demo-simple-select-label">User Type</InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={isOrganization}
                  label="User Type"
                  MenuProps={{
                    style: {
                      maxHeight: 200,
                      maxWidth: 200,
                    },
                  }}
                  onChange={(e) => setIsOrganization(e.target.value)}
                >
                  <MenuItem value={true} key="true">
                    Organization
                  </MenuItem>
                  <MenuItem value={false} key="false">
                    Individual
                  </MenuItem>
                </Select>
              </FormControl>

              {isOrganization ? (
                <>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="cname"
                    label="Company Name"
                    name="Company Name"
                    inputProps={{ maxLength: 20 }}
                    autoFocus
                    error={!!companyNameErrorText}
                    helperText={companyNameErrorText}
                    value={companyName}
                    onChange={(e) => {
                      // console.log("cname", /[\W_]/g.test(e.target.value));
                      setCompanyName(e.target.value);
                    }}
                  />
                </>
              ) : (
                <></>
              )}

              <TextField
                margin="normal"
                required
                fullWidth
                id="name"
                label="Name"
                name="name"
                autoFocus
                error={!!nameErrorText}
                helperText={nameErrorText}
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                error={!!emailErrorText}
                helperText={emailErrorText}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />

              <MuiTelInput
                required
                sx={{ marginTop: "10px;" }}
                defaultCountry={"IN"}
                value={phone}
                fullWidth
                MenuProps={{
                  style: {
                    maxHeight: 200,
                  },
                }}
                error={!!phoneErrorText}
                helperText={phoneErrorText}
                onChange={phoneChange}
              />

              {!isEdit ? (
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  error={!!passwordErrorText}
                  helperText={passwordErrorText}
                  autoComplete="current-password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              ) : (
                <></>
              )}

              <TextField
                margin="normal"
                fullWidth
                name="street"
                label="Street"
                type="text"
                id="street"
                autoComplete="street"
                value={street}
                onChange={(e) => setStreet(e.target.value)}
              />

              <FormControl sx={{ width: "100%", paddingRight: "5px;" }}>
                <TextField
                  margin="normal"
                  fullWidth
                  name="city"
                  label="City"
                  type="text"
                  id="city"
                  autoComplete="city"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
              </FormControl>

              <FormControl sx={{ width: "50%", paddingRight: "5px;" }}>
                <TextField
                  margin="normal"
                  fullWidth
                  name="pinCode"
                  label="PinCode"
                  type="number"
                  id="pinCode"
                  autoComplete="pinCode"
                  value={pinCode}
                  onChange={(e) => setPinCode(e.target.value)}
                />
              </FormControl>

              <FormControl
                sx={{ width: "50%", paddingLeft: "5px;", marginTop: "15px;" }}
              >
                <InputLabel id="demo-simple-select-label">Country</InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={country}
                  label="Country"
                  MenuProps={{
                    style: {
                      maxHeight: 200,
                      maxWidth: 200,
                    },
                  }}
                  onChange={(e) => setCountry(e.target.value)}
                >
                  {countryList.map((c) => (
                    <MenuItem value={c.iso3} key={c.iso3}>
                      {c.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={dialogClose}>Cancel</Button>
          <Button onClick={updateCompany}>{isEdit ? "Update" : "Add"}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
