import React, { useEffect, useState } from "react";
import { uploadFile } from "react-s3";
import { Buffer } from "buffer";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Typography,
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogTitle,
  TextField,
  DialogContent,
  DialogContentText,
  Stack,
  Skeleton,
  Box,
} from "@mui/material";
import moment from "moment/moment.js";
import apiService from "../../api";
import DeleteIcon from "@mui/icons-material/Delete";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import Loader from "../../components/Loader";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import LoadingButton from "@mui/lab/LoadingButton";

Buffer.from("anything", "base64");
window.Buffer = window.Buffer || require("buffer").Buffer;

const config = {
  bucketName: process.env.REACT_APP_S3_BUCKET,
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
};

const AdminOta = () => {
  const MySwal = withReactContent(Swal);
  const [otas, setOtas] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [updateId, setUpdateId] = useState("");
  const [version, setVersion] = useState("");
  const [description, setDescription] = useState("");
  const [fileLink, setFileLink] = useState("");
  const [loader, setLoader] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [saveBtnLoader, setSaveBtnLoader] = useState(false);
  const [operation, setOperation] = useState("create");
  const [preloader, setPreLoader] = useState(false);

  const getAllOtas = async () => {
    try {
      setPreLoader(true);
      const respo = await apiService.getAllOtas();
      setOtas(respo.data.data);
      setPreLoader(false);
    } catch (err) {
      console.error("respo error", err);
    }
  };

  const downloadFile = (fileLink) => {
    window.open(fileLink, "_blank");
  };

  const openOtaDialog = (ops, data) => {
    if (ops === "update") {
      setOperation("Update");
      setUpdateId(data._id);
      setVersion(data.version);
      setDescription(data.description);
      setFileLink(data.fileLink);
    } else {
      setOperation("Create");
    }
    setOpenDialog(true);
  };

  const closeDialog = () => {
    setVersion("");
    setDescription("");
    setFileLink("");
    setUpdateId("");
    setOpenDialog(false);
  };

  useEffect(() => {
    getAllOtas();
  }, []);

  const fileUpload = async (e) => {
    const file = e.target.files[0];
    try {
      setShowLoader(true);
      // const byte = await file.arrayBuffer();
      // let byteArray = new Int8Array(byte);
      // // let byteArray = new Int32Array(byte);
      // console.log("byte---------", byte);
      // console.log("-------", byteArray);
      // // function byteArraya(array) {
      // //   return String.fromCharCode.apply(String, array);
      // // }
      // // console.log("byteArray", byteArraya(byteArray));
      // let str = new TextDecoder().decode(byteArray);
      // console.log("string", str);

      const respo = await uploadFile(file, config);
      // console.log("respo", respo);
      setFileLink(respo.location);
      // setFileLink(respo.location);
      setShowLoader(false);
    } catch (err) {
      console.log(err);
      return MySwal.fire({
        title: <strong>Error!</strong>,
        html: <p>{err.response.data.message}</p>,
        icon: "error",
      });
    }
  };

  const itemUpdate = async () => {
    setSaveBtnLoader(true);
    if (operation === "Create") {
      await apiService.createOta(version, description, fileLink);
    } else {
      await apiService.updateOta(updateId, version, description, fileLink);
    }
    closeDialog();
    await getAllOtas();
    setSaveBtnLoader(false);
  };

  const deleteOta = async (otaId) => {
    try {
      const respo = await Swal.fire({
        title: "Are you sure?",
        text: "Are you delete OTA Update",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      });

      if (respo.isConfirmed) {
        setLoader(true);
        await apiService.deleteOta(otaId);
        await getAllOtas();
        setLoader(false);
        return MySwal.fire({
          title: <strong>Success!</strong>,
          html: <p>OTA Update Successfully</p>,
          icon: "success",
        });
      }
    } catch (err) {
      return MySwal.fire({
        title: <strong>Error!</strong>,
        html: <p>{err.response.data.message}</p>,
        icon: "error",
      });
    }
  };

  return (
    <div>
      <Grid
        container
        spacing={0}
        direction="column"
        style={{ minHeight: "100vh" }}
      >
        <Loader handleLoader={loader} />
        <Grid
          sx={{ padding: "10px 0px 0px 0px" }}
          container
          spacing={2}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid item xs={12} sm={8} md={8}>
            <Typography variant="h5" component="div">
              OTA Updated List
            </Typography>
            <Typography sx={{ fontSize: 14 }} variant="body2" gutterBottom>
              {moment(Date.now()).format("llll")}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <div style={{ display: "flex" }}>
              <Button
                style={{ marginLeft: "auto" }}
                variant="contained"
                disableElevation
                onClick={() => openOtaDialog("create")}
              >
                Add OTA Update
              </Button>
            </div>
          </Grid>
        </Grid>
        <br />
        <Grid item xs={12}>
          {/* <div>React S3 File Upload</div>
      <input type="file" onChange={handleFileInput} />
      <button onClick={() => handleUpload(selectedFile)}> Upload to S3</button> */}
          {preloader ? (
            <>
              <Grid item xs={12} sm={12}>
                <Paper
                  sx={{ p: 4, display: "flex", flexDirection: "row" }}
                  elevation={0}
                >
                  <Box sx={{ width: "100%", height: "350px" }}>
                    {[...Array(8)].map((_, index) => (
                      <Skeleton
                        variant="rounded"
                        key={index}
                        sx={{ my: 3, mx: 1 }}
                      />
                    ))}
                  </Box>
                </Paper>
              </Grid>
            </>
          ) : (
            <>
              <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <b>Version</b>
                      </TableCell>
                      <TableCell align="right">
                        <b>Dscription</b>
                      </TableCell>
                      <TableCell align="right">
                        <b>FileLink</b>
                      </TableCell>
                      <TableCell align="right">
                        <b>Created At</b>
                      </TableCell>
                      <TableCell align="right">
                        <b>Actions</b>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {otas.map((row) => (
                      <TableRow
                        key={row._id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.version}
                        </TableCell>
                        <TableCell align="right">{row.description}</TableCell>
                        <TableCell align="right">
                          <IconButton
                            onClick={() => downloadFile(row.fileLink)}
                          >
                            <CloudDownloadIcon color="info" />
                          </IconButton>
                        </TableCell>
                        <TableCell align="right">
                          {moment(row.createdAt).format("lll")}
                        </TableCell>
                        <TableCell align="right">
                          <IconButton
                            onClick={() => openOtaDialog("update", row)}
                          >
                            <BorderColorIcon color="info" />
                          </IconButton>
                          <IconButton
                            color="error"
                            onClick={() => deleteOta(row._id)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </Grid>

        <Dialog open={openDialog} onClose={closeDialog}>
          <DialogTitle>OTA {operation}</DialogTitle>
          <DialogContent>
            {/* <DialogContentText>
              To subscribe to this website, please enter your email address
              here. We will send updates occasionally.
            </DialogContentText> */}
            {/* <TextField
              autoFocus
              margin="dense"
              id="version"
              label="Version"
              type="text"
              fullWidth
              variant="standard"
              value={version}
              onChange={(e) => {
                setVersion(e.target.value);
              }}
            /> */}
            <TextField
              autoFocus
              margin="dense"
              id="description"
              label="Description"
              type="text"
              multiline
              rows={3}
              fullWidth
              variant="standard"
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            />
            <Stack direction="row" alignItems="center" spacing={2}>
              <TextField
                autoFocus
                margin="dense"
                id="fileLink"
                label="File Link"
                type="text"
                fullWidth
                disabled
                variant="standard"
                value={fileLink}
                onChange={(e) => {
                  setFileLink(e.target.value);
                }}
              />
              <LoadingButton
                loading={showLoader}
                variant="contained"
                component="label"
                sx={{ mt: 3, ml: 1 }}
                onChange={fileUpload}
              >
                Choose
                <input hidden type="file" />
              </LoadingButton>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              component="label"
              sx={{ mt: 3, ml: 1 }}
              color="error"
              onClick={closeDialog}
            >
              Cancel
            </Button>
            <LoadingButton
              loading={saveBtnLoader}
              variant="contained"
              component="label"
              sx={{ mt: 3, ml: 1 }}
              onClick={() => itemUpdate()}
            >
              {operation}
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </Grid>
    </div>
  );
};

export default AdminOta;
