import { ActionTypes } from "../constants/action-types";

// const initialState = {
//   products: [],
// };

// export const productsReducer = (state = initialState, { type, payload }) => {
//   switch (type) {
//     case ActionTypes.SET_PRODUCTS:
//       return { ...state, products: payload };
//     case ActionTypes.FETCH_PRODUCTS:
//       return { ...state, products: payload };
//     default:
//       return state;
//   }
// };

// export const selectedProductReducer = (state = {}, { type, payload }) => {
//   switch (type) {
//     case ActionTypes.SELECTED_PRODUCT:
//       return { ...state, ...payload };
//     case ActionTypes.REMOVE_SELECTED_PRODUCT:
//       return {};
//     default:
//       return state;
//   }
// };

const initialState = {
  user: null,
  token: null,
};

export const userReducer = (state = null, { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_USER:
      return { ...state, ...payload };
    case ActionTypes.GET_USER:
      return { ...state, ...payload };
    case ActionTypes.REMOVE_USER:
      return null;
    default:
      return state;
  }
};

// export const selectedProductReducer = (state = {}, { type, payload }) => {
//   switch (type) {
//     case ActionTypes.SELECTED_PRODUCT:
//       return { ...state, ...payload };
//     case ActionTypes.REMOVE_SELECTED_PRODUCT:
//       return {};
//     default:
//       return state;
//   }
// };
