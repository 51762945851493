import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { styled } from "@mui/material/styles";
// import Grid from "@mui/material/Unstable_Grid2";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
// import { useTheme } from "@mui/material/styles";
// import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
// import Container from "@mui/material/Container";
// import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import Checkbox from "@mui/material/Checkbox";
import countryList from "../../utils/country";
import { MuiTelInput } from "mui-tel-input";
import { setRef } from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useNavigate } from "react-router-dom";
import { Stack } from "@mui/material";
import moment from "moment/moment.js";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function CreateUserForm({
  modalOpen,
  isEdit,
  editUser,
  editDevice,
  modalClose,
}) {
  const [name, setName] = useState("");
  const [macAddress, setMac] = useState("");
  const [nameErrorText, setNameErrorText] = useState("");
  const [macErrorText, setMacErrorText] = useState("");
  const [startDate, setStartDate] = useState(
    moment().subtract(0, "days").format()
  );
  const [expiryDate, setExpiryDate] = useState();

  const dialogClose = () => {
    clearFields();
    modalClose();
  };

  const clearFields = () => {
    setName("");
    setMac("");
  };

  const updateDevice = (e) => {
    e.preventDefault();

    // name === "" ? setNameErrorText("Please enter name") : setNameErrorText("");
    macAddress === ""
      ? setMacErrorText("Please enter mac address")
      : setMacErrorText("");

    // if (name !== "" && macAddress !== "") {
    //console.log("123");
    //if (!isEdit) {
    // Edit User
    modalClose({
      isEdit,
      payload: {
        deviceId: editDevice._id,
        name: name,
        macAddress: macAddress,
        expiryDate: expiryDate,
      },
    });
    //}
    clearFields();
    // }
  };

  useEffect(() => {
    if (isEdit) {
      //console.log("updateUser", editDevice);
      setName(editDevice.name);
      setMac(editDevice.macAddress);
      setExpiryDate(editDevice.expiryDate);
    }
  }, [isEdit]);
  return (
    <div>
      <Dialog open={modalOpen}>
        <DialogTitle>{isEdit ? "Edit" : "Add"} Device</DialogTitle>
        <DialogContent style={{ width: "450px" }}>
          <Box
            sx={{
              // display: "flex",
              flexDirection: "column",
              alignItems: "start",
            }}
          >
            <Box component="form" noValidate>
              <TextField
                margin="normal"
                required
                fullWidth
                id="name"
                label="Name"
                name="name"
                autoFocus
                error={!!nameErrorText}
                helperText={nameErrorText}
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="macAddress"
                label="MAC Address"
                inputProps={{ style: { textTransform: "uppercase" } }}
                name="mac"
                autoComplete="mac"
                autoFocus
                error={!!macErrorText}
                helperText={macErrorText}
                value={macAddress}
                onChange={(e) => setMac(e.target.value)}
              />

              <LocalizationProvider dateAdapter={AdapterMoment}>
                <Stack spacing={3}>
                  <DatePicker
                    label="Expiry Date"
                    openTo="year"
                    views={["day"]}
                    inputFormat="DD/MM/YYYY"
                    value={expiryDate}
                    onChange={(newValue) => {
                      setExpiryDate(moment(newValue).format());
                    }}
                    renderInput={(params) => (
                      <TextField {...params} helperText={null} />
                    )}
                  />
                </Stack>
              </LocalizationProvider>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={dialogClose}>Cancel</Button>
          <Button onClick={updateDevice}>{isEdit ? "Update" : "Create"}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
