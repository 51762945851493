import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import {
  Paper,
  TextField,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { MuiTelInput } from "mui-tel-input";
import countryList from "../../utils/country";
import { useSelector } from "react-redux";
import apiService from "../../api/index";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { setCompany } from "../../redux/actions/companyActions";
import { useDispatch } from "react-redux";
import PageBreadcrumb from "../../components/PageBreadcrumb";
import moment from "moment/moment";
import LoadingButton from "@mui/lab/LoadingButton";

const Profile = () => {
  const MySwal = withReactContent(Swal);
  const [country, setCountry] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [city, setCity] = useState("");
  const [street, setStreet] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPincode] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  //const [emailErrorText, setEmailErrorText] = useState("");
  const [companyId, setCompanyId] = useState("");
  const dispatch = useDispatch();
  const company = useSelector((state) => state.company);

  const phoneChange = (newValue) => {
    setPhone(newValue);
  };
  const [showLoader, setShowLoader] = useState(false);

  const OnPageInit = () => {
    //  console.log("company12", company);
    setCompanyId(company._id);
    setCompanyName(company.name);
    setCountry(company.country);
    setCity(company.address.city);
    setStreet(company.address.street);
    setState(company.address.state);
    setPincode(company.address.pinCode);
    setEmail(company.email);
    setPhone(company.phone);

    // if (
    //   email === "" ||
    //   !/^[a-z0-9][a-z0-9-_.]+@([a-z]|[a-z0-9]?[a-z0-9-]+[a-z0-9]).[a-z0-9]{2,10}(?:.[a-z]{2,10})?$/.test(
    //     email
    //   )
    // ) {
    //   setEmailErrorText("Please enter valid email");
    // } else {
    //   setEmailErrorText("");
    // }

    // console.log("company", company._id);
  };
  useEffect(() => {
    OnPageInit();
  }, []);

  const updateCompany = async (companyId) => {
    if (
      companyName === "" ||
      country === "" ||
      city === "" ||
      street === "" ||
      state === "" ||
      pincode === "" ||
      email === "" ||
      phone === ""
    ) {
      return MySwal.fire({
        title: <strong>warning!</strong>,
        html: <p>please fill all the fields</p>,
        icon: "warning",
      });
    }

    let payload = {
      name: companyName,
      email: email,
      country: country,
      city: city,
      state: state,
      pinCode: pincode,
      phone: phone,
      street: street,
    };

    setShowLoader(true);
    try {
      const respo = await apiService.updateCompany(companyId, payload);
      await dispatch(setCompany(respo.data.company));
      setShowLoader(false);
      return MySwal.fire({
        title: <strong>success!</strong>,
        html: <p>{respo.data.message}</p>,
        icon: "success",
      });
    } catch (err) {
      setShowLoader(false);
      return MySwal.fire({
        title: <strong>Error!</strong>,
        html: <p>{err.response.data.message}</p>,
        icon: "error",
      });
    }
  };

  return (
    <div>
      <PageBreadcrumb pageName="Company Profile" />

      <Grid
        sx={{ padding: "10px 0px 0px 0px" }}
        container
        spacing={2}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        <Grid item xs={12}>
          <Typography variant="h5" component="div">
            Company Profile
          </Typography>
          <Typography sx={{ fontSize: 14 }} variant="body2" gutterBottom>
            {moment(Date.now()).format("llll")}
          </Typography>
        </Grid>
      </Grid>
      <br />
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{ minHeight: "100vh" }}
      >
        <Paper
          // variant="outlined"
          elevation={2}
          sx={{
            // my: { xs: 3, md: 6 },
            p: { xs: 2, md: 3 },
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="companyName"
                name="companyName"
                value={companyName}
                label="Name"
                fullWidth
                autoComplete="given-name"
                variant="outlined"
                onChange={(e) => setCompanyName(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="email"
                name="email"
                label="Email Address"
                value={email}
                //  error={!!emailErrorText}
                //helperText={emailErrorText}
                fullWidth
                autoComplete="family-name"
                variant="outlined"
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <MuiTelInput
                required
                defaultCountry={"IN"}
                fullWidth
                label="Phone"
                value={phone}
                onChange={phoneChange}
                MenuProps={{
                  style: {
                    maxHeight: 200,
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <Typography variant="h6" gutterBottom>
                Address
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12}>
              <TextField
                required
                id="street"
                name="street"
                label="Street"
                value={street}
                fullWidth
                autoComplete="family-name"
                variant="outlined"
                onChange={(e) => setStreet(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="state"
                name="state"
                label="State/Province/Region"
                value={state}
                fullWidth
                variant="outlined"
                onChange={(e) => setState(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl sx={{ width: "100%", paddingLeft: "5px;" }}>
                <InputLabel id="demo-simple-select-label">Country</InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={country}
                  label="Country"
                  MenuProps={{
                    style: {
                      maxHeight: 200,
                      maxWidth: 200,
                    },
                  }}
                  onChange={(e) => setCountry(e.target.value)}
                >
                  {countryList.map((c) => (
                    <MenuItem value={c.iso3} key={c.iso3}>
                      {c.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="city"
                name="city"
                label="City"
                value={city}
                fullWidth
                autoComplete="shipping address-level2"
                variant="outlined"
                onChange={(e) => setCity(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="zip"
                name="zip"
                label="Zip / Postal code"
                value={pincode}
                fullWidth
                autoComplete="shipping postal-code"
                variant="outlined"
                onChange={(e) => setPincode(e.target.value)}
              />
            </Grid>

            <Grid item xs={12}>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <LoadingButton
                  loading={showLoader}
                  variant="contained"
                  sx={{ mt: 3, ml: 1 }}
                  onClick={() => updateCompany(companyId)}
                >
                  Update
                </LoadingButton>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </div>
  );
};

export default Profile;
