import { ActionTypes } from "../constants/action-types";

export const setCompany = (company) => {
  return {
    type: ActionTypes.SET_COMPANY,
    payload: company,
  };
};

export const getCompany = (company) => {
  return {
    type: ActionTypes.GET_COMPANY,
    payload: company,
  };
};

export const removeCompany = () => {
  return {
    type: ActionTypes.REMOVE_COMPANY,
  };
};
