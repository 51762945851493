import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

const NoPageFound = () => {
  return (
    <div>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{ minHeight: "100vh" }}
      >
        <Grid item xs={3}>
          <Box
            component="img"
            src={require("../../assets/404.png")}
            alt="logo"
            sx={{
              width: "300px",
              height: "auto",
            }}
          />
        </Grid>
        <Typography variant="h4" component="div">
          No Page Found
        </Typography>
      </Grid>
    </div>
  );
};

export default NoPageFound;
