import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  TableRow,
  TableCell,
  TableHead,
  Paper,
  Grid,
  Card,
  CardContent,
  Typography,
  Tooltip,
  Modal,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Box,
  Button,
  Stack,
  InputBase,
} from "@mui/material";
import { experimentalStyled as styled } from "@mui/material/styles";
import Loader from "../../components/Loader";
import apiService from "../../api/index";
import moment from "moment/moment.js";
import { alpha } from "@mui/material/styles";
import CircleIcon from "@mui/icons-material/Circle";
import CreateDeviceForm from "../../components/adminCompany/adminDeviceCrud";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import QRCode from "react-qr-code";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoIcon from "@mui/icons-material/Info";
import DomainDisabledIcon from "@mui/icons-material/DomainDisabled";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function StickyHeadTable() {
  const MySwal = withReactContent(Swal);
  const [page, setPage] = React.useState(0);
  const [devices, setDevices] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [isEdit, setIsEdit] = React.useState(false);
  const [openModal, setopenModal] = React.useState(false);
  const [assignModal, setassignModal] = React.useState(false);
  const [companyAssignModal, setcompanyAssignModal] = React.useState(false);
  const [assigncompany, setAssignCompany] = React.useState("");
  const [device, setDevice] = React.useState("");
  const [editDevice, setEditDevice] = React.useState({});
  const [plan, setPlan] = React.useState("SUBSCRIPTION");
  const [subsIntervalShow, setSubsIntervalShow] = React.useState(true);
  const [noOfDays, setNoOfDays] = React.useState(0);
  let navigate = useNavigate();

  const [startDate, setStartDate] = useState(
    moment().subtract(0, "days").format()
  );
  const plans = [
    { text: "Subscription", value: "SUBSCRIPTION" },
    { text: "Direct", value: "DIRECT" },
  ];
  const handleChangesubscription = (event) => {
    const {
      target: { value },
    } = event;
    setPlan(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    setSubsIntervalShow(subsIntervalShow ? false : true);
  };

  const openDeviceCompanyModal = (deviceId) => {
    setAssignCompany("");
    setcompanyAssignModal(true);
    setDevice(deviceId);
    // console.log("openDeviceCompanyModal", getDeviceId);
  };

  const openEditModal = (props) => {
    // console.log("Edit Modal", props);
    setEditDevice(props);
    setIsEdit(true);
    setopenModal(true);
  };

  // const openDetails = async (device) => {
  //   console.log("device", device._id);
  //   //handleLoaderOpen(true);
  //   //handleLoaderOpen(false);
  //   navigate("/admin/devicedetails");
  // };
  const openDetails = async (device) => {
    console.log("device", device._id);
    navigate(`/admin/devicedetails/${device._id}`);
  };

  const assignDeviceCompany = async (event) => {
    setAssignCompany(event.target.value);
    //const resp = await apiService.companyassignByAdmin();
  };
  const submit = async () => {
    setOpen(true);
    setcompanyAssignModal(false);
    const resp = await apiService.companyassignByAdmin({
      deviceId: device,
      companyId: assigncompany,
      purchaseType: plan.toString(),
      // subscriptionInterval: noOfDays,
      expiryDate: startDate,
    });

    await getDevice();
    setOpen(false);
    await Swal.fire({
      text: "Device and company assigned successfully",

      icon: "success",
    });
    // console.log("resp", resp);
  };

  const [companies, setCompanies] = React.useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = (value) => {
    setOpen(value);
  };

  const [state, setState] = React.useState({
    online: true,
    offline: false,
  });
  const dialogClose = () => {
    setcompanyAssignModal(false);
  };

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };
  const openDeviceAddModal = () => {
    //  console.log("open");
    // setIsEdit(false);
    setopenModal(true);
  };
  // working start

  const modalClose = async (props) => {
    console.log("props", props);
    setopenModal(false);
    setIsEdit(false);
    if (typeof props !== "undefined") {
      try {
        let respo = {};
        setOpen(true);
        if (props.isEdit) {
          // Edit User
          console.log("...................................", props);
          respo = await apiService.updateDevice({
            deviceId: props.payload.deviceId,
            name: props.payload.name,
            macAddress: props.payload.macAddress,
            expiryDate: props.payload.expiryDate,
          });
        } else {
          // AddDevice
          // props.payload.location = [0, 0];
          props.payload.location = "";
          respo = await apiService.addDevice(props.payload);
        }
        await getDevice();
        setOpen(false);
        // setIsEdit(false);
        // setEditUser({});
        return MySwal.fire({
          title: <strong>success!</strong>,
          html: <p>{respo.data.message}</p>,
          icon: "success",
        });
      } catch (err) {
        setOpen(false);
        console.log("Error", err);
        return MySwal.fire({
          title: <strong>Error!</strong>,
          html: <p>{err.response.data.message}</p>,
          icon: "error",
        });
      }
    }
  };
  const getCompany = async () => {
    //console.log("getcompany");
    // handleClickOpen(true);
    const response = await apiService.getCompanyByAdmin();
    setCompanies(response.data.data);
    // handleClickOpen(false);
  };

  // working end

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  }));

  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: "100ch",
      },
    },
  }));
  const deleteDevice = async (device) => {
    console.log("xxxxxxxxx", device._id);
    const respo = await Swal.fire({
      title: "Are you sure?",
      text: "Are you delete device",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
    });
    let res = {};
    if (respo.isConfirmed) {
      handleClickOpen(true);
      //console.log("fffffff")
      res = await apiService.deleteDevice({
        deviceId: device._id,
      });
    }
    let e = res.data.message;
    await getDevice();
    handleClickOpen(false);

    await Swal.fire({
      text: "Device  deleted successfully",

      icon: "success",
    });
  };

  const disabledDevice = async (device) => {
    // console.log("xxxxxxxxx", device);
    const respo = await Swal.fire({
      title: "Are you sure?",
      text: "Are you delete company",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
    });

    let res = {};
    // console.log("cccccccccccccccc", device.companyId._id);
    if (respo.isConfirmed) {
      handleClickOpen(true);
      //console.log("fffffff")
      res = await apiService.disableDeviceByAdmin({
        deviceId: device._id,
        companyId: device.companyId._id,
      });
    }
    let e = res.data.message;

    await getDevice();
    handleClickOpen(false);

    await Swal.fire({
      text: "Device and company unassigned successfully",

      icon: "success",
    });

    // await apiService.disableDeviceByAdmin();
  };

  const getDevice = async () => {
    const response = await apiService.getAdminDevice();
    setDevices(response.data.data);
  };
  const onStart = async () => {
    handleClickOpen(true);
    await getDevice();
    await getCompany();
    handleClickOpen(false);
  };

  useEffect(() => {
    onStart();
  }, []);

  return (
    <div sx={{ width: "100%", overflow: "hidden" }}>
      <Modal
        open={companyAssignModal}
        //onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {/* {JSON.stringify(companies)} */}
        <Box sx={style}>
          <FormControl fullWidth>
            <InputLabel id="demo-multiple-name-label">Name</InputLabel>

            <Select
              labelId="demo-multiple-chip-label"
              id="demo-multiple-chip"
              //multiple
              value={assigncompany}
              //onChange={handleChange}
              input={<OutlinedInput id="select-multiple-chip" />}
              onChange={assignDeviceCompany}
            >
              {companies.map((company, index) => (
                <MenuItem key={index} value={company._id}>
                  {company.name}
                </MenuItem>
              ))}
            </Select>
            <br />
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="demo-multiple-name-label1">
              Subscription Type
            </InputLabel>

            <Select
              labelId="demo-multiple-chip-label"
              id="demo-multiple-chip"
              //multiple
              value={plan}
              //onChange={handleChange}
              input={<OutlinedInput id="select-multiple-chip" />}
              onChange={handleChangesubscription}
              //MenuProps={MenuProps}
            >
              {plans.map((plan, index) => (
                <MenuItem
                  key={index}
                  value={plan.value}
                  // style={getStyles(name, personName, theme)}
                >
                  {plan.text}
                </MenuItem>
              ))}
            </Select>
            <br />

            {subsIntervalShow ? (
              <Box
                sx={{
                  width: 500,
                  maxWidth: "100%",
                }}
              >
                {/* <TextField
                  fullWidth
                  label="SUbscription Interval(Days)"
                  type="number"
                  value={noOfDays}
                  id="fullWidth"
                  onChange={(event) => {
                    setNoOfDays(event.target.value);
                  }}
                /> */}
                <Box>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <Stack spacing={3}>
                      <DatePicker
                        label="Expiry Date"
                        openTo="year"
                        views={["day"]}
                        inputFormat="DD/MM/YYYY"
                        value={startDate}
                        onChange={(newValue) => {
                          setStartDate(moment(newValue).endOf("day"));
                        }}
                        renderInput={(params) => (
                          <TextField {...params} helperText={null} />
                        )}
                      />
                    </Stack>
                  </LocalizationProvider>
                </Box>
              </Box>
            ) : (
              <></>
            )}

            <br />
          </FormControl>

          <Button variant="contained" onClick={submit}>
            submit
          </Button>

          <Button onClick={dialogClose}>Cancel</Button>

          {/* <Typography id="modal-modal-title" variant="h6" component="h2">
            Text in a modal
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
          </Typography> */}
        </Box>
      </Modal>

      {/* <AddCompany open={assignModal} companies={companies} /> */}
      <Grid
        container
        spacing={2}
        sx={{ padding: "10px" }}
        columns={{ xs: 4, sm: 12, md: 12 }}
      >
        <Grid item xs={12} sm={8} md={8} lg={8}>
          <Typography variant="h5" component="div">
            Device List
          </Typography>
          <Typography sx={{ fontSize: 14 }} variant="body2" gutterBottom>
            {moment(Date.now()).format("llll")}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={4} md={4} lg={4}>
          <div style={{ display: "flex" }}>
            <CreateDeviceForm
              modalOpen={openModal}
              isEdit={isEdit}
              editDevice={editDevice}
              modalClose={(props) => modalClose(props)}
            />
            <Button
              style={{ marginLeft: "auto" }}
              variant="contained"
              disableElevation
              startIcon={<AddCircleOutlineIcon />}
              onClick={() => openDeviceAddModal()}
            >
              Create Device
            </Button>
          </div>
        </Grid>
      </Grid>

      <Loader handleLoader={open} />
      <br />

      {/* <QRCode value="hey" /> */}

      {devices.length > 0 ? (
        <>
          {/* <TableContainer>
            <Table
              stickyHeader
              aria-label="sticky table"
              sx={{ marginBottom: "20px", padding: "10px" }}
            >
              <TableBody>
                <TableRow>
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid
                      container
                      spacing={{ xs: 2, md: 3 }}
                      columns={{ xs: 12, sm: 6, md: 12 }}
                    >
                      {devices.map((device, index) => (
                        <Grid item xs={12} sm={4} md={4} lg={4} key={index}>
                          <Card
                            sx={{
                              minWidth: 300,
                              display: "block",
                              transitionDuration: "0.3s",
                              height: 350,
                            }}
                          >
                            <CardContent>
                              <Grid container spacing={2} columns={12}>
                                <Grid item xs={10}>
                                  <Typography variant="h6" component="div">
                                    Device: {device.name}
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  xs={2}
                                  justifyContent="flex-end"
                                  display={"flex"}
                                >
                                  {device.disabled ? (
                                    <Typography
                                      color="text.secondary"
                                      style={{ textAlign: "end" }}
                                    >
                                      <Tooltip title="Disable">
                                        <CircleIcon color="error" />
                                      </Tooltip>
                                    </Typography>
                                  ) : (
                                    <Typography
                                      color="text.secondary"
                                      style={{ alignItems: "end" }}
                                    >
                                      <Tooltip title="Live">
                                        <CircleIcon color="success" />
                                      </Tooltip>
                                    </Typography>
                                  )}
                                </Grid>
                              </Grid>

                              {device.companyId && device.companyId["name"] ? (
                                <Typography
                                  sx={{ fontSize: 18 }}
                                  variant="body2"
                                  gutterBottom
                                >
                                  Company:{" "}
                                  {device.companyId && device.companyId["name"]}
                                  <Tooltip title="Un assigned Company">
                                    <IconButton
                                      aria-label="add to favorites"
                                      onClick={() => disabledDevice(device)}
                                    >
                                      <RemoveCircleIcon color="error" />
                                    </IconButton>
                                  </Tooltip>
                                </Typography>
                              ) : (
                                <Typography
                                  sx={{ fontSize: 18 }}
                                  variant="body2"
                                  gutterBottom
                                >
                                  Assign Company
                                  <Tooltip title="Assigned Company">
                                    <IconButton
                                      aria-label="add to favorites"
                                      onClick={() =>
                                        openDeviceCompanyModal(device._id)
                                      }
                                    >
                                      <AddCircleIcon color="success" />
                                    </IconButton>
                                  </Tooltip>
                                </Typography>
                              )}
                              <Typography
                                sx={{ fontSize: 16 }}
                                variant="body2"
                                gutterBottom
                              >
                                MAC: {device.macAddress}
                              </Typography>

                              <Typography
                                sx={{ fontSize: 16 }}
                                variant="body2"
                                gutterBottom
                              >
                                SalesType: {device.purchaseType}
                              </Typography>

                              <Typography
                                sx={{ fontSize: 16 }}
                                variant="body2"
                                gutterBottom
                              >
                                Expiry Date:{" "}
                                {moment(device.expiryDate).format("DD-MM-YYYY")}{" "}
                                {moment(device.expiryDate).diff(
                                  moment(),
                                  "days"
                                ) < 0 ? (
                                  <>
                                    [ Already Expire ]
                                    <Tooltip title="Disable">
                                      <CircleIcon color="error" />
                                    </Tooltip>
                                  </>
                                ) : (
                                  ` [ ${moment(device.expiryDate).diff(
                                    moment(),
                                    "days"
                                  )} days remain ]`
                                )}
                              </Typography>

                              <Typography
                                sx={{ fontSize: 16 }}
                                variant="body2"
                                gutterBottom
                              >
                                Created At:{" "}
                                {moment(device.createdAt).format("DD-MM-YYYY")}
                              </Typography>

                              <Grid container spacing={2} columns={12}>
                                <Grid item xs={4}>
                                  <Tooltip title="edit device">
                                    <IconButton
                                      aria-label="add to favorites"
                                      onClick={() => openEditModal(device)}
                                    >
                                      <EditIcon color="primary" />
                                    </IconButton>
                                  </Tooltip>
                                </Grid>

                                <Grid item xs={3}>
                                  <IconButton
                                    aria-label="add to favorites"
                                    onClick={() => openDetails(device)}
                                  >
                                    <InfoIcon color="primary" />
                                  </IconButton>
                                </Grid>
                                <Grid item xs={4}>
                                  <Tooltip title="delete device">
                                    <IconButton
                                      aria-label="add to favorites"
                                      onClick={() => deleteDevice(device)}
                                    >
                                      <DeleteIcon color="error" />
                                    </IconButton>
                                  </Tooltip>
                                </Grid>

                                <Grid
                                  item
                                  xs={4}
                                  justifyContent="flex-end"
                                  display={"flex"}
                                >
                                  <div
                                    style={{
                                      height: "auto",
                                      margin: "0 auto",
                                      maxWidth: 64,
                                      width: "100%",
                                    }}
                                  >
                                    <QRCode
                                      size={256}
                                      style={{
                                        height: "auto",
                                        maxWidth: "100%",
                                        width: "100%",
                                      }}
                                      value={device.macAddress}
                                      viewBox={`0 0 256 256`}
                                    />
                                  </div>
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer> */}

          <TableContainer component={Paper}>
            <Table aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>Device</TableCell>
                  <TableCell align="right">Company</TableCell>
                  <TableCell align="right">Onboard Done</TableCell>
                  <TableCell align="right">MAC</TableCell>
                  <TableCell align="right">SalesType</TableCell>
                  <TableCell align="right">Expiry Date</TableCell>
                  <TableCell align="right"> Created At</TableCell>
                  <TableCell align="right">Operation</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {devices.map((device, index) => (
                  <TableRow
                    key={device._id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {device.name}
                    </TableCell>
                    <TableCell component="th" scope="row" align="right">
                      {device.companyId && device.companyId["name"] ? (
                        <Typography
                          sx={{ fontSize: 18 }}
                          variant="body2"
                          gutterBottom
                        >
                          {" "}
                          {device.companyId && device.companyId["name"]}
                          <Tooltip title="Unassigned Company">
                            <IconButton
                              aria-label="add to favorites"
                              onClick={() => disabledDevice(device)}
                            >
                              <RemoveCircleIcon color="error" />
                            </IconButton>
                          </Tooltip>
                        </Typography>
                      ) : (
                        <Typography
                          sx={{ fontSize: 18 }}
                          variant="body2"
                          gutterBottom
                        >
                          Assign Company
                          <Tooltip title="Assigned Company">
                            <IconButton
                              aria-label="add to favorites"
                              onClick={() => openDeviceCompanyModal(device._id)}
                            >
                              <AddCircleIcon color="success" />
                            </IconButton>
                          </Tooltip>
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell component="th" scope="row" align="right">
                      {device.onboardDone.toString()}
                    </TableCell>
                    <TableCell component="th" scope="row" align="right">
                      {device.macAddress}
                    </TableCell>
                    <TableCell align="right">{device.purchaseType}</TableCell>
                    <TableCell align="right">
                      {moment(device.expiryDate).format("DD-MM-YYYY")}
                      <br />
                      {moment(device.expiryDate).diff(moment(), "days") < 0 ? (
                        <>
                          <span style={{ backgroundColor: "#FF7171" }}>
                            [ Already Expire ]
                          </span>
                        </>
                      ) : (
                        <span style={{ backgroundColor: "#CCFFF2" }}>
                          [ {moment(device.expiryDate).diff(moment(), "days")}{" "}
                          days remain ]
                        </span>
                      )}
                    </TableCell>
                    <TableCell align="right">
                      {" "}
                      {moment(device.createdAt).format("DD-MM-YYYY")}
                    </TableCell>
                    <TableCell align="right">
                      <Tooltip title="Edit device">
                        <IconButton
                          aria-label="add to favorites"
                          onClick={() => openEditModal(device)}
                        >
                          <EditIcon color="primary" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Open device">
                        <IconButton
                          aria-label="add to favorites"
                          onClick={() => openDetails(device)}
                        >
                          <InfoIcon color="primary" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete device">
                        <IconButton
                          aria-label="add to favorites"
                          onClick={() => deleteDevice(device)}
                        >
                          <DeleteIcon color="error" />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            // count={Array.length}
            count={devices.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      ) : (
        <>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
            style={{ minHeight: "100vh" }}
          >
            <Grid item xs={3}>
              <Box
                component="img"
                src={require("../../assets/NoData2.jpg")}
                alt="logo"
                sx={{
                  width: "300px",
                  height: "auto",
                }}
              />
            </Grid>
            <Typography variant="h5" component="div">
              No Device Found
            </Typography>
          </Grid>
        </>
      )}
    </div>
  );
}
