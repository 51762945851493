import React, { useEffect, useState } from "react";
import apiService from "../../api/index";
import { useParams } from "react-router-dom";
import { Box, Grid, Paper, TextField, Stack, Typography } from "@mui/material";
import moment from "moment/moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import LoadingButton from "@mui/lab/LoadingButton";
const AdminDeviceDetails = () => {
  const [device, setDevice] = useState({});
  const [deviceId, setDeviceId] = useState("");
  const [company, setCompany] = useState({});
  const params = useParams();
  const [showPage, setShowPage] = useState(true);

  const [startDate, setStartDate] = useState(
    moment().subtract(7, "days").format()
  );

  const [endDate, setEndDate] = useState(moment().format());
  const [showLoader, setShowLoader] = useState(false);
  const [summaryData, setSummaryData] = useState([
    {
      _id: "RECHARGE",
      total: 0,
    },
    {
      _id: "START",
      total: 0,
    },
  ]);

  useEffect(() => {
    if (typeof params.deviceId == "undefined" || params.deviceId === "") {
      setShowPage(false);
    }
    setDeviceId(params.deviceId);
    onStart();
  }, []);

  const onStart = async () => {
    await Promise.all([getDevice(), getFilterSummary()]);
    // console.log("-----", params.deviceId);
  };

  const getFilterSummary = async () => {
    const response = await apiService.logByFilterSummary({
      deviceId: params.deviceId,
      startDate: startDate,
      endDate: endDate,
    });
    // console.log("-------", response.data.data);
    if (response.data.data.length > 0) {
      setSummaryData(response.data.data);
    } else {
      setSummaryData([
        {
          _id: "RECHARGE",
          total: 0,
        },
        {
          _id: "START",
          total: 0,
        },
      ]);
    }
  };

  const getDevice = async () => {
    const response = await apiService.getDeviceById(params.deviceId);
    // const device = JSON.parse(JSON.stringify(response.data.body));
    // console.log("----", device);
    setCompany(response.data.body.companyId);
    setDevice(response.data.body);
  };

  const onsubmit = () => {
    getFilterSummary();
  };

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      style={{ minHeight: "100vh" }}
    >
      {/* <Grid container spacing={2}> */}
      {/* Preloader Code Here */}

      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "row",
            width: "100%",
          }}
          elevation={0}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <Box>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <Stack spacing={3}>
                    <DatePicker
                      disableFuture
                      label="Start Date"
                      openTo="year"
                      views={["day"]}
                      inputFormat="DD/MM/YYYY"
                      value={startDate}
                      onChange={(newValue) => {
                        setStartDate(moment(newValue).startOf("day").format());
                      }}
                      renderInput={(params) => (
                        <TextField {...params} helperText={null} />
                      )}
                    />
                  </Stack>
                </LocalizationProvider>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <Box>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <Stack spacing={3}>
                    <DatePicker
                      disableFuture
                      label="End Date"
                      openTo="year"
                      views={["day"]}
                      value={endDate}
                      inputFormat="DD/MM/YYYY"
                      onChange={(newValue) => {
                        setEndDate(moment(newValue).endOf("day").format());
                      }}
                      renderInput={(params) => (
                        <TextField {...params} helperText={null} />
                      )}
                    />
                  </Stack>
                </LocalizationProvider>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <Box>
                <LoadingButton
                  type="submit"
                  fullWidth
                  loading={showLoader}
                  variant="contained"
                  sx={{ mt: 1, mb: 0 }}
                  onClick={onsubmit}
                >
                  Submit
                </LoadingButton>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      <br />

      <Grid item xs={12} sm={4} md={4} lg={4}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "row",
            width: "100%",
          }}
          elevation={0}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Typography variant="body2" color="textSecondary" component="p">
                Name : {device.name}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                Company: {company.name}
              </Typography>

              <Typography variant="body2" color="textSecondary" component="p">
                purchaseType : {device.purchaseType}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                Expiry Date : {moment(device.expiryDate).format("DD-MM-YYYY")}{" "}
                {moment(device.expiryDate).diff(moment(), "days") < 0 ? (
                  <>
                    <span style={{ backgroundColor: "#FF7171" }}>
                      [ Already Expire ]
                    </span>
                  </>
                ) : (
                  <span style={{ backgroundColor: "#CCFFF2" }}>
                    [ {moment(device.expiryDate).diff(moment(), "days")} days
                    remain ]
                  </span>
                )}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                macAddress : {device.macAddress}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                Created At: : {moment(device.createdAt).format("DD-MM-YYYY")}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              {summaryData.map((d, index) => (
                <div key={index}>
                  <Typography variant="h4" gutterBottom>
                    {d._id === "START" ? "INHALE" : d._id}
                  </Typography>
                  <Typography variant="h6" gutterBottom>
                    {Math.floor(Number(d.total) / 60)} Min [ {d.total} Sec ]
                  </Typography>
                </div>
              ))}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
    // </Grid>
  );
};
export default AdminDeviceDetails;
