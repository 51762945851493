import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { styled } from "@mui/material/styles";
// import Grid from "@mui/material/Unstable_Grid2";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
// import { useTheme } from "@mui/material/styles";
// import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
// import Container from "@mui/material/Container";
// import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import Checkbox from "@mui/material/Checkbox";
import countryList from "../../utils/country";
import { MuiTelInput } from "mui-tel-input";
import { setRef } from "@mui/material";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function CreateUserForm({
  modalOpen,
  isEdit,
  editUser,
  modalClose,
}) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("user");
  const [country, setCountry] = useState("IND");
  const [phone, setPhone] = useState("");
  const [nameErrorText, setNameErrorText] = useState("");
  const [emailErrorText, setEmailErrorText] = useState("");
  const [phoneErrorText, setPhoneErrorText] = useState("");
  const [passwordErrorText, setPasswordErrorText] = useState("");

  const phoneChange = (newValue) => {
    setPhone(newValue);
  };

  const dialogClose = () => {
    clearFields();
    modalClose();
  };

  const clearFields = () => {
    setName("");
    setEmail("");
    setPassword("");
    setCountry("IND");
    setPhone("");
    setRole("user");
  };

  const updateUser = (e) => {
    e.preventDefault();

    name === "" ? setNameErrorText("Please enter name") : setNameErrorText("");
    email === ""
      ? setEmailErrorText("Please enter email")
      : setEmailErrorText("");
    phone === ""
      ? setPhoneErrorText("Please enter phone")
      : setPhoneErrorText("");

    !isEdit && password === ""
      ? setPasswordErrorText("Please enter password")
      : setPasswordErrorText("");

    if (name !== "" && email !== "" && phone !== "") {
      if (isEdit) {
        // Edit User
        modalClose({
          isEdit,
          payload: {
            userId: editUser._id,
            name: name,
            email: email,
            phone: phone,
            role: role,
            country: country,
          },
        });
      } else {
        if (password !== "") {
          // Add User
          modalClose({
            isEdit,
            payload: {
              name: name,
              email: email,
              password: password,
              phone: phone,
              role: role,
              country: country,
            },
          });
        }
      }
      clearFields();
    }
  };

  useEffect(() => {
    if (isEdit) {
      // console.log("updateUser", editUser);
      setName(editUser.name);
      setEmail(editUser.email);
      setPhone(editUser.phone);
      setCountry(
        typeof editUser.country === "undefined" ? "IND" : editUser.country
      );
      setRole(editUser.role);
    }
  }, [isEdit]);
  return (
    <div>
      <Dialog open={modalOpen}>
        <DialogTitle>{isEdit ? "Edit" : "Add"} User</DialogTitle>
        <DialogContent style={{ width: "450px" }}>
          <Box
            sx={{
              // display: "flex",
              flexDirection: "column",
              alignItems: "start",
            }}
          >
            <Box component="form" noValidate>
              <TextField
                margin="normal"
                required
                fullWidth
                id="name"
                label="Name"
                name="name"
                autoFocus
                error={!!nameErrorText}
                helperText={nameErrorText}
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                error={!!emailErrorText}
                helperText={emailErrorText}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />

              <MuiTelInput
                required
                sx={{ marginTop: "10px;" }}
                defaultCountry={"IN"}
                value={phone}
                fullWidth
                MenuProps={{
                  style: {
                    maxHeight: 200,
                  },
                }}
                error={!!phoneErrorText}
                helperText={phoneErrorText}
                onChange={phoneChange}
              />

              {!isEdit ? (
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  error={!!passwordErrorText}
                  helperText={passwordErrorText}
                  autoComplete="current-password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              ) : (
                <></>
              )}

              <FormControl
                sx={{ width: "50%", paddingRight: "5px;", marginTop: "10px;" }}
              >
                <InputLabel id="demo-simple-select-label">Role</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={role}
                  label="Role"
                  MenuProps={{
                    style: {
                      maxHeight: 200,
                      maxWidth: 200,
                    },
                  }}
                  onChange={(e) => setRole(e.target.value)}
                >
                  <MenuItem value="admin" key="admin">
                    Admin
                  </MenuItem>
                  <MenuItem value="user" key="user">
                    User
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl
                sx={{ width: "50%", paddingLeft: "5px;", marginTop: "10px;" }}
              >
                <InputLabel id="demo-simple-select-label">Country</InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={country}
                  label="Role"
                  MenuProps={{
                    style: {
                      maxHeight: 200,
                      maxWidth: 200,
                    },
                  }}
                  onChange={(e) => setCountry(e.target.value)}
                >
                  {countryList.map((c) => (
                    <MenuItem value={c.iso3} key={c.iso3}>
                      {c.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={dialogClose}>Cancel</Button>
          <Button onClick={updateUser}>{isEdit ? "Update" : "Create"}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
