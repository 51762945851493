import React, { useEffect, useState } from "react";
import apiService from "../../api/index";
import { useParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import moment from "moment/moment.js";
import {
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  TableRow,
  Card,
  CardContent,
  Typography,
  Tooltip,
  Modal,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Button,
  Stack,
  InputBase,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import ExpiryDateUpdate from "../../components/adminCompany/adminExpiryDateUpdate";
import Loader from "../../components/Loader";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const AdminCompanyDetails = () => {
  const MySwal = withReactContent(Swal);
  const [company, setCompany] = useState([]);
  const [device, setDevice] = useState([]);
  const [companyId, setCompanyId] = useState("");
  const params = useParams();
  const [showPage, setShowPage] = useState(true);
  const [startDate, setStartDate] = useState(
    moment().subtract(0, "days").format()
  );
  const [openModal, setopenModal] = useState(false);
  const [editDate, setEditDate] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const handleClickOpen = (value) => {
    setOpen(value);
  };

  useEffect(() => {
    // getDevice();
    getCompany();
    onStart();
  }, []);
  const onStart = async () => {
    if (typeof params.companyId == "undefined" || params.companyId == "") {
      setShowPage(false);
    }
    setCompanyId(params.companyId);
    getCompany();

    console.log("-----", params.companyId);
  };

  const getCompany = async () => {
    handleClickOpen(true);
    // console.log("Company", params.CompanyId);
    const response = await apiService.getCompanyByID(params.companyId);
    setCompany(response.data.company);
    setDevice(response.data.devices);
    // console.log("response", response.data.devices);
    handleClickOpen(false);
  };
  // const openEditModal = (device) => {
  //   console.log("Edit Modal", device);

  //   // setEditDevice(props);
  //   // setIsEdit(true);
  //   setopenModal(true);
  // };

  const openEditModal = (props) => {
    // console.log("Edit Modal", props);
    setEditDate(props);
    setIsEdit(true);
    setopenModal(true);
  };
  const getDevice = async () => {
    const response = await apiService.getAdminDevice();
    setDevice(response.data.data);
  };

  const exdatemodalClose = async (props) => {
    handleClickOpen(true);

    // console.log("props", props);
    setopenModal(false);
    setIsEdit(false);
    if (typeof props !== "undefined") {
      try {
        let respo = {};
        // setOpen(true);
        if (props.isEdit) {
          // Edit User
          // console.log("...................................", props);
          respo = await apiService.updateDate({
            deviceId: props.payload.deviceId,
            expiryDate: moment(props.payload.expiryDate).endOf("day"),
          });
          //console.log("respo", respo);
        } else {
          // AddDevice
          // props.payload.location = [0, 0];
          props.payload.location = "";
          // respo = await apiService.addDevice(props.payload);
        }
        await getCompany();
        //  setOpen(false);
        // setIsEdit(false);
        // setEditUser({});
        handleClickOpen(false);
        return MySwal.fire({
          title: <strong>success!</strong>,
          html: <p>{respo.data.message}</p>,
          icon: "success",
        });
      } catch (err) {
        // setOpen(false);
        console.log("Error", err);
        handleClickOpen(false);
        return MySwal.fire({
          title: <strong>Error!</strong>,
          html: <p>{err.response.data.message}</p>,
          icon: "error",
        });
      }
    }
  };

  return (
    <div sx={{ width: "100%", overflow: "hidden" }}>
      <Loader handleLoader={open} />

      {device.length > 0 ? (
        <Card sx={{ flexGrow: 1 }}>
          {/* <Grid container spacing={2}>
            
            <Grid item xs={3}>
              <Typography variant="h6" component="div">
                Company Name:{company.name}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="h6" component="div">
                Created At:{company.createdAt}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="h6" component="div">
                Email:{company.email}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="h6" component="div">
                Phone:{company.phone}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="h6" component="div">
                Country:{company.country}
              </Typography>
            </Grid>
          </Grid> */}
          <tr>
            <TableContainer>
              <Table
                stickyHeader
                aria-label="sticky table"
                sx={{ marginBottom: "20px", padding: "10px" }}
              >
                <TableBody>
                  <TableRow>
                    <Box sx={{ flexGrow: 1 }}>
                      <Grid
                        container
                        spacing={{ xs: 4, md: 20 }}
                        columns={{ xs: 4, sm: 8, md: 12 }}
                      >
                        {device.map((device, index) => (
                          <Grid item xs={12} sm={4} md={4} lg={4} key={index}>
                            <Card
                              sx={{
                                minWidth: 235,
                                display: "block",
                                transitionDuration: "0.3s",
                                height: 350,
                                // width: 230,
                              }}
                            >
                              <CardContent>
                                <Grid container spacing={2} columns={12}>
                                  <Grid item xs={10}>
                                    <Typography variant="h6" component="div">
                                      Device: {device.name}
                                    </Typography>
                                  </Grid>
                                </Grid>

                                {/* <Typography
                                sx={{ fontSize: 16 }}
                                variant="body2"
                                gutterBottom
                              >
                                Created At:{" "}
                                {moment(device.createdAt).format("DD-MM-YYYY")}
                              </Typography> */}
                                <Typography
                                  sx={{ fontSize: 16 }}
                                  variant="body2"
                                  gutterBottom
                                >
                                  MAC: {device.macAddress}
                                </Typography>
                                <Typography
                                  sx={{ fontSize: 16 }}
                                  variant="body2"
                                  gutterBottom
                                >
                                  Location: {device.location}
                                </Typography>
                                {device.onboardDone ? (
                                  <Typography
                                  // sx={{ mb: 1.5 }}

                                  // style={{ textAlign: "end" }}
                                  >
                                    onboardDone
                                  </Typography>
                                ) : (
                                  <Typography
                                  // sx={{ mb: 1.5 }}

                                  //   style={{ alignItems: "end" }}
                                  >
                                    {/* online */}
                                    onboard not done
                                  </Typography>
                                )}
                                <Typography
                                  sx={{ fontSize: 16 }}
                                  variant="body2"
                                  gutterBottom
                                >
                                  purchaseType: {device.purchaseType}
                                </Typography>
                                <Typography
                                  sx={{ fontSize: 16 }}
                                  variant="body2"
                                  gutterBottom
                                >
                                  Created At:{" "}
                                  {moment(device.createdAt).format(
                                    "DD-MM-YYYY"
                                  )}
                                </Typography>
                                <Typography
                                  sx={{ fontSize: 16 }}
                                  variant="body2"
                                  gutterBottom
                                >
                                  ExpiryDate:{" "}
                                  {moment(device.expiryDate).format(
                                    "DD-MM-YYYY"
                                  )}
                                </Typography>

                                <Grid container spacing={2} columns={12}>
                                  <Grid item xs={3}>
                                    <IconButton
                                      aria-label="add to favorites"
                                      onClick={() => openEditModal(device)}
                                    >
                                      <EditIcon color="primary" />
                                    </IconButton>
                                  </Grid>
                                </Grid>

                                {openModal ? (
                                  <Box>
                                    <Grid item xs={12} sm={4} md={4} lg={4}>
                                      <div style={{ display: "flex" }}>
                                        <ExpiryDateUpdate
                                          modalOpen={openModal}
                                          isEdit={isEdit}
                                          editDevice={editDate}
                                          modalClose={(props) =>
                                            exdatemodalClose(props)
                                          }
                                        />
                                      </div>
                                    </Grid>
                                  </Box>
                                ) : (
                                  <></>
                                )}

                                {/* <Box>
                                  <LocalizationProvider
                                    dateAdapter={AdapterMoment}
                                  >
                                    <Stack spacing={3}>
                                      <DatePicker
                                        label="Expiry Date"
                                        openTo="year"
                                        views={["day"]}
                                        inputFormat="DD/MM/YYYY"
                                        value={startDate}
                                        onChange={(newValue) => {
                                          setStartDate(
                                            moment(newValue).endOf("day")
                                          );
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            helperText={null}
                                          />
                                        )}
                                      />
                                    </Stack>
                                  </LocalizationProvider>
                                </Box> */}
                              </CardContent>
                            </Card>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            {/* <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            // count={Array.length}
            count={devices.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
          </tr>
        </Card>
      ) : (
        <Typography
          sx={{ fontSize: 26 }}
          textAlign="center"
          variant="body2"
          gutterBottom
        >
          No Device Found
        </Typography>
      )}

      {/* 
          {showPage ? (
            <h1>{company.name}</h1>
          ) : (
            <h1>sorry can not fetch details</h1>
          )} */}
    </div>
  );
};
export default AdminCompanyDetails;
