import * as React from "react";
import {
  Box,
  Grid,
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
  IconButton,
  Paper,
  InputBase,
  InputAdornment,
  Skeleton,
} from "@mui/material";
import PageBreadcrumb from "../../components/PageBreadcrumb";
import CreateUserForm from "../../components/userCrud/CreateUserForm";
import { useEffect } from "react";
import apiService from "../../api/index";
import moment from "moment/moment.js";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";

export default function StickyHeadTable() {
  const MySwal = withReactContent(Swal);
  const [page, setPage] = React.useState(0);
  const [editUser, setEditUser] = React.useState({});
  const [users, setUsers] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [openModal, setopenModal] = React.useState(false);
  const [preLoader, setPreLoader] = React.useState(false);
  const [searchText, setSearchText] = React.useState("");
  const [filterUsers, setFilterusers] = React.useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const openAddModal = () => {
    setIsEdit(false);
    setopenModal(true);
  };

  const openEditModal = (props) => {
    //  console.log("Edit Modal", props);
    setEditUser(props);
    setIsEdit(true);
    setopenModal(true);
  };

  const removeAccount = (props) => {
    console.log("Remove Modal", props);
  };

  const modalClose = async (props) => {
    // console.log("props", props);
    setopenModal(false);
    setIsEdit(false);
    if (typeof props !== "undefined") {
      try {
        let respo = {};
        setOpen(true);
        if (props.isEdit) {
          // Edit User
          // console.log("Account edit call", props.payload);
          respo = await apiService.updateUser(props.payload.userId, {
            name: props.payload.name,
            email: props.payload.email,
            role: props.payload.role,
            phone: props.payload.phone,
            country: props.payload.country,
          });
        } else {
          // AddUser
          respo = await apiService.addUser(props.payload);
        }
        await getUsers();
        setOpen(false);
        // setIsEdit(false);
        // setEditUser({});
        return MySwal.fire({
          title: <strong>success!</strong>,
          html: <p>{respo.data.message}</p>,
          icon: "success",
        });
      } catch (err) {
        setOpen(false);
        // console.log("Error", err);
        return MySwal.fire({
          title: <strong>Error!</strong>,
          html: <p>{err.response.data.message}</p>,
          icon: "error",
        });
      }
    }
  };

  const getUsers = async () => {
    setPreLoader(true);
    const response = await apiService.getUsers();
    // const users = response.data.data.map((u) => {
    //   if (u.country == "") {
    //     u.country = "IND";
    //     return u;
    //   }
    // });
    setUsers(response.data.data);
    setFilterusers(response.data.data);
    setPreLoader(false);
  };
  const searchUser = (e) => {
    e.preventDefault();
    const searchText = e.target.value;
    setSearchText(searchText);
    setFilterusers(
      users.filter((d) =>
        d.name.toLowerCase().includes(searchText.toLowerCase())
      )
    );
  };
  const clearSearch = () => {
    setSearchText("");
    setFilterusers(users);
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      style={{ minHeight: "100vh" }}
    >
      <PageBreadcrumb pageName="User" />
      <Grid
        sx={{ padding: "10px 0px 0px 0px" }}
        container
        spacing={2}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        <Grid item xs={12} sm={8} md={8} lg={8}>
          <Typography variant="h5" component="div">
            User List
          </Typography>
          <Typography sx={{ fontSize: 14 }} variant="body2" gutterBottom>
            {moment(Date.now()).format("llll")}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4}>
          <div style={{ display: "flex" }}>
            <CreateUserForm
              modalOpen={openModal}
              isEdit={isEdit}
              editUser={editUser}
              modalClose={(props) => modalClose(props)}
            />
            {preLoader ? (
              <>
                <Skeleton
                  variant="h3"
                  animation="wave"
                  width="100%"
                  height="30px"
                />
              </>
            ) : (
              <>
                <Button
                  style={{ marginLeft: "auto" }}
                  variant="contained"
                  disableElevation
                  onClick={() => openAddModal()}
                  startIcon={<AddCircleOutlineIcon />}
                >
                  Create User
                </Button>
              </>
            )}
          </div>
        </Grid>
      </Grid>

      <br />
      <Paper
        component="form"
        sx={{
          p: "2px 4px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Search Users"
          inputProps={{ "aria-label": "search device" }}
          value={searchText}
          onChange={(e) => {
            searchUser(e);
          }}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
        />
        <IconButton
          type="button"
          sx={{
            p: "10px",
            visibility: searchText.length > 0 ? "visible" : "hidden",
          }}
          aria-label="search"
          onClick={clearSearch}
        >
          <ClearIcon />
        </IconButton>
      </Paper>

      <br />

      {preLoader ? (
        <>
          <Box sx={{ flexGrow: 1 }}>
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              {[...Array(6)].map((_, index) => (
                <Grid item xs={12} sm={4} md={4} lg={4} key={index}>
                  <Card
                    sx={{
                      minWidth: 175,
                      display: "block",
                      transitionDuration: "0.3s",
                      height: 250,
                    }}
                  >
                    <CardContent>
                      <Skeleton
                        variant="h3"
                        animation="wave"
                        sx={{ width: "100%", height: "22px" }}
                      />
                      <br />
                      <Skeleton
                        variant="h3"
                        animation="wave"
                        sx={{ width: "90%", height: "18px" }}
                      />
                      <br />
                      <Skeleton
                        variant="h3"
                        animation="wave"
                        sx={{ width: "70%", height: "20px" }}
                      />
                      <br />
                      <Skeleton
                        variant="h3"
                        animation="wave"
                        sx={{ width: "100%", height: "16px" }}
                      />
                    </CardContent>
                    <CardActions
                      disableSpacing
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      {[...Array(2)].map((_, index) => (
                        <Skeleton
                          variant="rounded"
                          animation="wave"
                          width={50}
                          height={30}
                          sx={{ margin: "5px" }}
                          key={index}
                        />
                      ))}
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        </>
      ) : (
        <>
          {filterUsers.length > 0 ? (
            <>
              <Box sx={{ flexGrow: 1 }}>
                <Grid
                  container
                  spacing={{ xs: 2, md: 3 }}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                >
                  {filterUsers.map((user, index) => (
                    <Grid item xs={12} sm={4} md={4} lg={4} key={index}>
                      <Card
                        sx={{
                          minWidth: 175,
                          display: "block",
                          transitionDuration: "0.3s",
                          height: 250,
                        }}
                      >
                        <CardContent>
                          <Grid container spacing={2} columns={16}>
                            <Grid item xs={12}>
                              <Typography variant="h5" component="div">
                                {user.name}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Typography variant="h6" component="div">
                            {user.role}
                          </Typography>

                          <Typography
                            sx={{ fontSize: 16 }}
                            variant="body2"
                            gutterBottom
                          >
                            Created At:{" "}
                            {moment(user.createdAt).format("DD-MM-YYYY")}
                          </Typography>

                          <Typography sx={{ fontSize: 16 }} variant="body2">
                            {user.email}
                          </Typography>
                          <Typography sx={{ fontSize: 16 }} variant="body2">
                            {user.phone}
                          </Typography>
                        </CardContent>
                        <CardActions
                          disableSpacing
                          sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <IconButton
                            aria-label="add to favorites"
                            onClick={() => openEditModal(user)}
                          >
                            <EditIcon color="primary" />
                          </IconButton>
                          <IconButton
                            aria-label="share"
                            onClick={() => removeAccount(user._id)}
                          >
                            <DeleteIcon color="error" />
                          </IconButton>
                        </CardActions>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </>
          ) : (
            <>
              <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
                style={{ minHeight: "100vh" }}
              >
                <Grid item xs={3}>
                  <Box
                    component="img"
                    src={require("../../assets/NoData2.jpg")}
                    alt="logo"
                    sx={{
                      width: "300px",
                      height: "auto",
                    }}
                  />
                </Grid>
                <Typography variant="h5" component="div">
                  No Users Found
                </Typography>
              </Grid>
            </>
          )}
        </>
      )}
    </Grid>
  );
}
