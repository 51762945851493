import * as React from "react";
// import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
// import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Loader from "../../components/Loader";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import apiService from "../../api/index";
import moment from "moment/moment.js";
// import FormControl from "@mui/material/FormControl";
// import FormGroup from "@mui/material/FormGroup";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import Switch from "@mui/material/Switch";
import Button from "@mui/material/Button";
import { alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
// import SearchIcon from "@mui/icons-material/Search";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import CircleIcon from "@mui/icons-material/Circle";
import Tooltip from "@mui/material/Tooltip";
import AdminCompanyCrudForm from "../../components/adminCompany/adminCompanyCrud";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import country from "../../utils/country";
import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";

// const Item = styled(Paper)(({ theme }) => ({
//   backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
//   ...theme.typography.body2,
//   padding: theme.spacing(2),
//   textAlign: "center",
//   color: theme.palette.text.secondary,
// }));
// const bull = (
//   <Box
//     component="span"
//     sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
//   >
//     •
//   </Box>
// );

// const deleteUserDeviceRelation = () => {
//   console.log("delete rel api called");
// };

// const UserChip = (users) => (
//   <div>
//     <br />
//     <Divider />
//     <br />
//     <Stack direction="row" spacing={1}>
//       {users.users.map((d, index) => (
//         <Chip
//           key={index}
//           label={d.name}
//           variant="outlined"
//           onDelete={deleteUserDeviceRelation}
//         />
//       ))}
//     </Stack>
//   </div>
// );

export default function AdminCompany() {
  const MySwal = withReactContent(Swal);
  const [page, setPage] = React.useState(0);
  const [companyes, setCompanyes] = React.useState([]);
  const [openModal, setopenModal] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const [editUser, setEditUser] = React.useState({});
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [editCompany, setEditCompany] = React.useState({});
  let navigate = useNavigate();

  const openDetailsModal = (props) => {
    console.log("Details Modal", props);
    setEditCompany(props);
    setIsEdit(true);
    setopenModal(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = (value) => {
    setOpen(value);
  };

  const [state, setState] = React.useState({
    online: true,
    offline: false,
  });

  const modalClose = async (props) => {
    // console.log("props", props);
    setopenModal(false);
    setIsEdit(false);
    if (typeof props !== "undefined") {
      try {
        let respo = {};
        setOpen(true);
        if (props.isEdit) {
          // Edit User
          //  console.log("Account edit call", props.payload);
          //   respo = await apiService.updateUser(props.payload.userId, {
          //     name: props.payload.name,
          //     email: props.payload.email,
          //     role: props.payload.role,
          //     phone: props.payload.phone,
          //     country: props.payload.country,
          //   });
        } else {
          //  console.log("Account add call", props.payload);

          // AddUser
          respo = await apiService.addCompanyByAdmin(props.payload);
        }
        await getCompany();
        setOpen(false);
        // setIsEdit(false);
        // setEditUser({});
        return MySwal.fire({
          title: <strong>success!</strong>,
          html: <p>{respo.data.message}</p>,
          icon: "success",
        });
      } catch (err) {
        setOpen(false);
        // console.log("Error", err);
        return MySwal.fire({
          title: <strong>Error!</strong>,
          html: <p>{err.response.data.message}</p>,
          icon: "error",
        });
      }
    }
  };

  const openAddModal = () => {
    setIsEdit(false);
    setopenModal(true);
  };

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  }));

  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: "100ch",
      },
    },
  }));
  // const info = (company) => {
  //   // handleLoaderOpen(true);
  //   // handleLoaderOpen(false);
  //   // navigate("/admin");
  //   console.log("redirect", company);
  //   // window.location.reload();
  // };
  const info = async (company) => {
    console.log("company", company._id);
    navigate(`/admin/comanydetails/${company._id}`);
  };

  const getCompany = async () => {
    handleClickOpen(true);
    const response = await apiService.getCompanyByAdmin();
    let companies = response.data.data.map((company) => {
      let cName = country.find((c) => {
        if (c.iso3 === company.country) return c;
        return false;
      });
      if (cName) company.countryName = cName.name;
      return company;
    });
    setCompanyes(companies);
    handleClickOpen(false);
  };
  const deleteCompany = async (company) => {
    console.log("xxxxxxxxx", company._id);
    const respo = await Swal.fire({
      title: "Are you sure?",
      text: "Are you delete company",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
    });
    let res = {};
    if (respo.isConfirmed) {
      handleClickOpen(true);
      //console.log("fffffff")
      res = await apiService.deleteCompany({
        companyId: company._id,
      });
    }
    let e = res.data.message;
    await getCompany();
    handleClickOpen(false);

    await Swal.fire({
      text: "Company  deleted successfully",

      icon: "success",
    });
  };

  useEffect(() => {
    getCompany();
  }, []);

  return (
    <div sx={{ width: "100%", overflow: "hidden" }}>
      <Grid
        container
        spacing={2}
        sx={{ padding: "10px" }}
        columns={{ xs: 4, sm: 12, md: 12 }}
      >
        <Grid item xs={12} sm={8} md={8} lg={8}>
          <Typography variant="h5" component="div">
            Company List
          </Typography>
          <Typography sx={{ fontSize: 14 }} variant="body2" gutterBottom>
            {moment(Date.now()).format("llll")}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4}>
          <div style={{ display: "flex" }}>
            <AdminCompanyCrudForm
              modalOpen={openModal}
              isEdit={isEdit}
              editUser={editUser}
              modalClose={(props) => modalClose(props)}
            />
            <Button
              style={{ marginLeft: "auto" }}
              variant="contained"
              disableElevation
              onClick={() => openAddModal()}
              startIcon={<AddCircleOutlineIcon />}
            >
              Add Company
            </Button>
          </div>
        </Grid>
      </Grid>

      <br />
      {/* <div style={{ border: "ridge" }}>
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            // style={{ width: "100px" }}
            placeholder="Search"
            inputProps={{ "aria-label": "search" }}
          />
        </Search>
      </div> */}

      <Loader handleLoader={open} />

      <TableContainer>
        <Table
          stickyHeader
          aria-label="sticky table"
          sx={{ marginBottom: "20px", padding: "10px" }}
        >
          <TableHead>
            <TableRow>
              <Box sx={{ flexGrow: 1 }}>
                <Grid
                  container
                  spacing={{ xs: 2, md: 3 }}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                >
                  {companyes.map((company, index) => (
                    <Grid item xs={12} sm={4} md={4} lg={4} key={index}>
                      <Card
                        sx={{
                          minWidth: 175,
                          display: "block",
                          transitionDuration: "0.3s",
                          height: 250,
                        }}
                      >
                        <CardContent>
                          <Grid container spacing={2} columns={16}>
                            <Grid item xs={10}>
                              <Typography variant="h5" component="div">
                                {company.name}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={6}
                              justifyContent="flex-end"
                              display={"flex"}
                            >
                              {company.disabled ? (
                                <Typography
                                  sx={{ mb: 1.5 }}
                                  color="text.secondary"
                                  style={{ textAlign: "end" }}
                                >
                                  {/* offline */}
                                  <Tooltip title="Disable">
                                    <CircleIcon color="error" />
                                  </Tooltip>
                                </Typography>
                              ) : (
                                <Typography
                                  sx={{ mb: 1.5 }}
                                  color="text.secondary"
                                  style={{ alignItems: "end" }}
                                >
                                  {/* online */}
                                  <Tooltip title="Live">
                                    <CircleIcon color="success" />
                                  </Tooltip>
                                </Typography>
                              )}
                            </Grid>
                          </Grid>

                          {/* <Typography variant="h5" component="div">
                            {device.name}
                          </Typography> */}
                          {/* <p style={{ textAlign: "end" }}>{device.name}</p> */}
                          {company.email ? (
                            <>
                              <Typography
                                sx={{ fontSize: 14 }}
                                variant="body2"
                                gutterBottom
                              >
                                Email: {company.email}
                              </Typography>
                            </>
                          ) : (
                            <></>
                          )}

                          {company.phone ? (
                            <>
                              <Typography
                                sx={{ fontSize: 14 }}
                                variant="body2"
                                gutterBottom
                              >
                                Phone: {company.phone}
                              </Typography>
                            </>
                          ) : (
                            <></>
                          )}

                          <Typography
                            sx={{ fontSize: 14 }}
                            variant="body2"
                            gutterBottom
                          >
                            Created At:{" "}
                            {moment(company.createdAt).format("DD-MM-YYYY")}
                          </Typography>

                          <Typography
                            sx={{ fontSize: 14 }}
                            variant="body2"
                            gutterBottom
                          >
                            Country: {company.countryName}
                          </Typography>

                          <Grid item xs={4}>
                            <Tooltip title="delete company">
                              <IconButton
                                aria-label="add to favorites"
                                onClick={() => deleteCompany(company)}
                              >
                                <DeleteIcon color="error" />
                              </IconButton>
                            </Tooltip>
                          </Grid>

                          <Grid
                            item
                            xs={20}
                            justifyContent="flex-end"
                            display={"flex"}
                          >
                            <Tooltip title="how many device attached with company">
                              <IconButton
                                aria-label="add to favorites"
                                onClick={() => info(company)}
                              >
                                <InfoIcon color="primary" />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                          <br />
                          {/* {device.disabled ? (
                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                              Status:offline
                            </Typography>
                          ) : (
                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                              Status:online
                            </Typography>
                          )} */}
                          {/* {company.deleted ? (
                            <Typography sx={{ mb: 1.5 }} variant="body2">
                              Comapny Deleted
                            </Typography>
                          ) : (
                            <Typography sx={{ mb: 1.5 }} variant="body2">
                              Company Activate
                            </Typography>
                          )}
                          <Typography variant="body2">
                            State:{company.state}
                          </Typography> */}
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </Box>
              {/* {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))} */}
            </TableRow>
          </TableHead>

          <TableBody>
            {/* {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })} */}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        // count={Array.length}
        count={companyes.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
}
