import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Paper,
  Button,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  OutlinedInput,
  Chip,
  TextField,
  Stack,
  Skeleton,
  TablePagination,
  Typography,
  // Item,
} from "@mui/material";
// import LogDatatable from "../../components/userLog/LogDatatable";
import LogDataTableV2 from "../../components/userLog/LogDataTableV2";
import PageBreadcrumb from "../../components/PageBreadcrumb";
import apiService from "../../api/index";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment/moment";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import LoadingButton from "@mui/lab/LoadingButton";
import LogDataGraph from "../../components/userLog/LogDataGraph";
import Chart from "../../components/userDahboard/Chart";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "auto",
    },
  },
};

const DataTableSkeleton = () => {
  return (
    <>
      <Grid item xs={12} sm={12}>
        <Paper
          sx={{ p: 4, display: "flex", flexDirection: "row" }}
          elevation={0}
        >
          <Box sx={{ width: "100%", height: "350px" }}>
            {[...Array(8)].map((_, index) => (
              <Skeleton variant="rounded" key={index} sx={{ my: 3, mx: 1 }} />
            ))}
          </Box>
        </Paper>
      </Grid>
    </>
  );
};

export default function Log() {
  const swal = withReactContent(Swal);
  const [startDate, setStartDate] = useState(
    moment().subtract(6, "days").format()
  );

  const [endDate, setEndDate] = useState(moment().format());
  const [selectedUser, setSelectedUser] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState([]);
  const [allDevice, setAllDevice] = useState([]);
  const [allUser, setAllUser] = useState([]);
  const [logs, setLogs] = useState([]);
  const [logCount, setLogCount] = useState(100);
  const [rowPerPage, setRowPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [preLoader, setPreLoader] = useState(false);
  const [dataTableLoader, setDataTableLoader] = useState(false);
  const [initCheck, setInitCheck] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [graphData, setGraphData] = useState([]);
  const [summaryData, setSummaryData] = useState([
    {
      _id: "RECHARGE",
      total: 0,
    },
    {
      _id: "START",
      total: 0,
    },
  ]);

  // Get All Logs
  const getLogData = async (limit, skip) => {
    const response = await apiService.logByFilter({
      queryCase: "data",
      devices: getDevicesPayload(selectedDevice, allDevice),
      users: getUsersPayload(selectedUser, allUser),
      startDate: moment(startDate).format(),
      endDate: moment(endDate).format(),
      limit: limit,
      skip: skip,
    });

    setLogs(response.data.data);
  };

  const getLogCount = async () => {
    const response = await apiService.logByFilter({
      queryCase: "count",
      devices: getDevicesPayload(selectedDevice, allDevice),
      users: getUsersPayload(selectedUser, allUser),
      startDate: moment(startDate).format(),
      endDate: moment(endDate).format(),
    });

    setLogCount(response.data.data.count);
  };

  //get logbyfiltergraph
  const getLogGraphByFilter = async () => {
    const response = await apiService.logByFilterGraph({
      devices: getDevicesPayload(selectedDevice, allDevice),
      users: getUsersPayload(selectedUser, allUser),
      startDate: moment(startDate).format(),
      endDate: moment(endDate).format(),
    });

    // console.log(response.data.data);
    setGraphData(response.data.data);
  };

  // Get All Devices
  const getAllDevices = async () => {
    const response = await apiService.getDevicesByCompanyId();
    let devices = response.data.data;
    devices.unshift({ _id: "allDevice", name: "All Device" });
    if (devices.length > 0) {
      setSelectedDevice([devices[0]]);
    }
    setAllDevice(devices);
  };

  // Get All Users
  const getAllUsers = async () => {
    const response = await apiService.getUsers();
    let users = response.data.data;
    users.unshift({ _id: "allUser", name: "All User" });
    if (users.length > 0) {
      setSelectedUser([users[0]]);
    }
    setAllUser(response.data.data);
  };

  const getUsersPayload = (selectedUser, users) => {
    let lUsers = selectedUser;
    if (
      selectedUser.find((s) => {
        return s._id === "allUser";
      })
    ) {
      lUsers = users.filter((u) => {
        if (u._id !== "allUser") return u;
      });
    }

    return lUsers.map((u) => u._id);
  };

  const getDevicesPayload = (selectedDevice, devices) => {
    let lDevices = selectedDevice;
    if (
      selectedDevice.find((s) => {
        return s._id === "allDevice";
      })
    ) {
      lDevices = devices.filter((d) => {
        if (d._id !== "allDevice") return d;
      });
    }

    return lDevices.map((d) => d._id);
  };

  const onsubmit = async () => {
    // check blank device
    if (selectedUser.length === 0) {
      return swal.fire({
        title: <strong>Error!</strong>,
        html: <p>Users can't blank.</p>,
        icon: "error",
      });
    }

    // check blank device
    if (selectedDevice.length === 0) {
      return swal.fire({
        title: <strong>Error!</strong>,
        html: <p>Devices can't blank.</p>,
        icon: "error",
      });
    }
    setShowLoader(true);
    setDataTableLoader(true);
    await Promise.all([getLogCount(), getLogGraphByFilter(), getLogData(5, 0)]);
    setDataTableLoader(false);
    setShowLoader(false);
  };

  const handleChangeRowsPerPage = async (event) => {
    // console.log("Row_Per_Page_Change", parseInt(event.target.value, 10));

    setDataTableLoader(true);
    setRowPerPage(parseInt(event.target.value, 10));
    setPage(0);
    await getLogData(parseInt(event.target.value, 10), 0);
    setDataTableLoader(false);
  };

  const handleChangePage = async (event, newPage) => {
    // console.log("Page_Change", newPage);
    setDataTableLoader(true);
    setPage(Number(newPage));
    await getLogData(rowPerPage, Number(newPage) * Number(rowPerPage));
    setDataTableLoader(false);
  };

  // Get All Log OnInit Check
  useEffect(() => {
    if (initCheck) {
      logInit();
    }
  }, [initCheck]);

  const logInit = async () => {
    await Promise.all([
      getLogCount(),
      getLogGraphByFilter(),
      logByFilterSummary(),
      getLogData(rowPerPage, 0),
    ]);
  };
  const logByFilterSummary = async () => {
    const response = await apiService.logByFilterSummary({
      startDate: startDate,
      endDate: endDate,
    });

    if (response.data.data.length > 0) {
      setSummaryData(response.data.data);
    }
  };

  // Page Init
  const pageInit = async () => {
    setPreLoader(true);
    setDataTableLoader(true);
    await Promise.all([getAllUsers(), getLogGraphByFilter(), getAllDevices()]);
    setInitCheck(true);
    setPreLoader(false);
    setDataTableLoader(false);
  };

  useEffect(() => {
    pageInit();
  }, []);

  const handleUser = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedUser(typeof value === "string" ? value.split(",") : value);
  };

  const handleDevice = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedDevice(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      style={{ minHeight: "100vh" }}
    >
      <Grid container spacing={2}>
        <Grid container sx={{ marginLeft: "20px", marginTop: "15px" }}>
          <PageBreadcrumb pageName="Report" />
          <Grid
            sx={{ padding: "10px 0px 0px 0px" }}
            container
            spacing={2}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            <Grid item xs={12}>
              <Typography variant="h5" component="div">
                Report
              </Typography>
              <Typography sx={{ fontSize: 14 }} variant="body2" gutterBottom>
                {moment(Date.now()).format("llll")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={4} md={4} lg={4}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  height: 400,
                }}
                elevation={2}
              >
                {preLoader ? (
                  <>
                    <Grid item xs={12}>
                      <Paper
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                        }}
                        elevation={0}
                      >
                        <Grid container spacing={2}>
                          {[...Array(5)].map((_, index) => (
                            <Grid item xs={12} key={index}>
                              <Skeleton
                                variant="rounded"
                                animation="wave"
                                sx={{ width: "100%", height: "50px" }}
                              />
                            </Grid>
                          ))}
                        </Grid>
                      </Paper>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={12}>
                      <Paper
                        sx={{
                          // p: 2,
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                        }}
                        elevation={0}
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Box>
                              <FormControl fullWidth>
                                <InputLabel id="demo-multiple-name-label">
                                  User
                                </InputLabel>

                                <Select
                                  SelectDisplayProps={{
                                    style: {
                                      paddingTop: 12,
                                      paddingBottom: 12,
                                    },
                                  }}
                                  labelId="demo-multiple-chip-label"
                                  id="demo-multiple-chip"
                                  multiple
                                  value={selectedUser}
                                  onChange={handleUser}
                                  input={
                                    <OutlinedInput
                                      id="select-multiple-chip"
                                      label="Chip"
                                    />
                                  }
                                  renderValue={(selected) => (
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                        gap: 0.5,
                                      }}
                                    >
                                      {selected.map((value) => (
                                        <Chip
                                          key={value._id}
                                          label={value.name}
                                        />
                                      ))}
                                    </Box>
                                  )}
                                  MenuProps={MenuProps}
                                >
                                  {allUser.map((person, index) => (
                                    <MenuItem
                                      key={index}
                                      value={person}
                                      // style={getStyles(name, personName, theme)}
                                    >
                                      {person.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Box>
                          </Grid>

                          <Grid item xs={12}>
                            <Box>
                              <FormControl fullWidth>
                                <InputLabel id="demo-multiple-name-label">
                                  Device
                                </InputLabel>

                                <Select
                                  SelectDisplayProps={{
                                    style: {
                                      paddingTop: 12,
                                      paddingBottom: 12,
                                    },
                                  }}
                                  labelId="demo-multiple-chip-label"
                                  id="demo-multiple-chip"
                                  multiple
                                  value={selectedDevice}
                                  onChange={handleDevice}
                                  input={
                                    <OutlinedInput
                                      id="select-multiple-chip"
                                      label="Chip"
                                    />
                                  }
                                  renderValue={(selected) => (
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                        gap: 0.5,
                                      }}
                                    >
                                      {selected.map((value) => (
                                        <Chip
                                          key={value._id}
                                          label={value.name}
                                        />
                                      ))}
                                    </Box>
                                  )}
                                  MenuProps={MenuProps}
                                >
                                  {allDevice.map((device, index) => (
                                    <MenuItem
                                      key={index}
                                      value={device}
                                      // style={getDevices(device, deviceName, theme)}
                                    >
                                      {device.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Box>
                          </Grid>
                          <Grid item xs={12}>
                            <Box>
                              <LocalizationProvider dateAdapter={AdapterMoment}>
                                <Stack spacing={3}>
                                  <DatePicker
                                    disableFuture
                                    label="Start Date"
                                    openTo="year"
                                    views={["day"]}
                                    inputFormat="DD/MM/YYYY"
                                    value={startDate}
                                    onChange={(newValue) => {
                                      setStartDate(
                                        moment(newValue).startOf("day").format()
                                      );
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        helperText={null}
                                      />
                                    )}
                                  />
                                </Stack>
                              </LocalizationProvider>
                            </Box>
                          </Grid>
                          <Grid item xs={12}>
                            <Box>
                              <LocalizationProvider dateAdapter={AdapterMoment}>
                                <Stack spacing={3}>
                                  <DatePicker
                                    disableFuture
                                    label="End Date"
                                    openTo="year"
                                    views={["day"]}
                                    value={endDate}
                                    inputFormat="DD/MM/YYYY"
                                    onChange={(newValue) => {
                                      setEndDate(
                                        moment(newValue).endOf("day").format()
                                      );
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        helperText={null}
                                      />
                                    )}
                                  />
                                </Stack>
                              </LocalizationProvider>
                            </Box>
                          </Grid>
                          <Grid item xs={12}>
                            <Box>
                              <LoadingButton
                                type="submit"
                                fullWidth
                                loading={showLoader}
                                variant="contained"
                                // sx={{ mt: 1, mb: 0 }}
                                onClick={onsubmit}
                              >
                                Submit
                              </LoadingButton>
                            </Box>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>

                    <Grid item xs={12} sm={8} md={8} lg={8}></Grid>
                  </>
                )}
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={8} md={8} lg={8}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: 320,
            }}
            elevation={2}
          >
            {preLoader ? (
              <>
                {[...Array(1)].map((_, index) => (
                  <Grid item xs={12} key={index}>
                    <Skeleton
                      variant="rounded"
                      animation="wave"
                      sx={{ width: "100%", height: "320px" }}
                    />
                  </Grid>
                ))}
              </>
            ) : (
              <LogDataGraph
                graphData={graphData}
                startDate={startDate}
                endDate={endDate}
              />
            )}
          </Paper>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: 70,
              marginTop: "10px",
            }}
            elevation={2}
          >
            <Typography variant="h6" gutterBottom>
              Total Recharge :{" "}
              {
                summaryData.find((s) => {
                  if (s._id === "RECHARGE") return s;
                }).total
              }
              , Total Inhale:
              {
                summaryData.find((s) => {
                  if (s._id === "START") return s;
                }).total
              }
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          {(() => {
            if (dataTableLoader) {
              return <DataTableSkeleton />;
            } else {
              return (
                <>
                  <Grid item xs={12}>
                    {(() => {
                      if (logs.length > 0) {
                        return (
                          <>
                            <LogDataTableV2 logs={logs} />
                            <TablePagination
                              component="div"
                              count={logCount}
                              page={page}
                              onPageChange={handleChangePage}
                              rowsPerPage={rowPerPage}
                              rowsPerPageOptions={[5, 10, 25, 50, 100]}
                              onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                          </>
                        );
                      } else {
                        return (
                          <>
                            <Grid
                              container
                              spacing={0}
                              direction="column"
                              alignItems="center"
                              justify="center"
                            >
                              <Grid item xs={3}>
                                <Box
                                  component="img"
                                  src={require("../../assets/NoData3.png")}
                                  alt="logo"
                                  sx={{
                                    width: "300px",
                                    height: "auto",
                                  }}
                                />
                              </Grid>
                              <Typography variant="h5" component="div">
                                Your report appears to be empty.
                              </Typography>
                            </Grid>
                          </>
                        );
                      }
                    })()}
                  </Grid>
                </>
              );
            }
          })()}
        </Grid>
      </Grid>
    </Grid>
  );
}
