import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import Checkbox from "@mui/material/Checkbox";
// import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Image from "../../assets/login.jpg";
import { useDispatch } from "react-redux";
import { setUser } from "../../redux/actions/userActions";
import { setToken } from "../../redux/actions/tokenActions";
import { setCompany } from "../../redux/actions/companyActions";
import apiService from "../../api/index";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Link } from "react-router-dom";

import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const theme = createTheme();

export default function SignInSide() {
  const MySwal = withReactContent(Swal);
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (value) => {
    setOpen(value);
  };

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get("email");
    const password = data.get("password");

    if (email === "" || password === "") {
      return MySwal.fire({
        title: <strong>Error!</strong>,
        html: <p>Please insert valid email or password</p>,
        icon: "error",
      });
    }

    try {
      handleClickOpen(true);
      //  console.log("lol", password, email);
      const response = await apiService.userLogin({
        email: email,
        password: password,
      });

      const user = response.data.user;
      handleClickOpen(false);
      if (user.role === "user") {
        return MySwal.fire({
          title: <strong>Error!</strong>,
          html: <p>Only Admin can access dashboard panel</p>,
          icon: "error",
        });
      }

      await dispatch(setToken(response.data.token));
      await dispatch(setUser(response.data.user));
      await dispatch(setCompany(response.data.company));

      navigate("/dashboard");
    } catch (e) {
      handleClickOpen(false);
      console.log("eroor", e);
      return MySwal.fire({
        title: <strong>Error!</strong>,
        html: <p>{e.response.data.message}</p>,
        icon: "error",
      });
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Loader handleLoader={open} />

      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={false}
          md={7}
          lg={7}
          sx={{
            backgroundImage: `url(${Image})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid
          item
          xs={12}
          sm={12}
          md={5}
          lg={5}
          component={Paper}
          elevation={1}
          square
        >
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "20%",
            }}
          >
            <Typography component="h1" variant="h5"></Typography>
            {/* <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar> */}
            {/* <Typography component="h1" variant="h5">
              Evolve O2
            </Typography> */}
            <Box
              component="img"
              src={require("../../assets/logo3.png")}
              alt="logo"
              sx={{
                left: "50%",
                top: "50%",
                height: "auto",
                width: "300px",
                flex: 1,
                justifyContent: "start",
                alignContent: "start",
              }}
            />

            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1 }}
            >
              <FormControl sx={{ m: 1 }} variant="outlined" fullWidth>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                />
              </FormControl>

              <FormControl sx={{ m: 1 }} variant="outlined" fullWidth>
                <InputLabel htmlFor="password">Password</InputLabel>
                <OutlinedInput
                  id="password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
              </FormControl>
              {/* <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              /> */}
              {/* <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              /> */}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>
              {/* <Button
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                component={Link}
                to="/admin"
              >
                go to Admin
              </Button> */}
            </Box>
            {/* <Link href="#" variant="body2">
              Forgot password?
            </Link> */}
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
