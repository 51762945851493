import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { withWidth } from "@material-ui/core";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import moment from "moment/moment.js";

const styles = (theme) => ({
  card: {
    maxWidth: "auto",
    marginTop: "5px",
  },
  table: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
});

const LogDataTable = ({ classes, width, logs }) => {
  const isSmallScreen = width === "xs";

  if (isSmallScreen) {
    return (
      <div>
        {logs.map((row) => (
          <Card key={row._id} className={classes.card} sx={{ p: 2 }}>
            <CardContent>
              <Typography color="textPrimary">
                {" "}
                Date: {moment.unix(row.timeStamp).format("lll")}
              </Typography>
              <Typography color="textPrimary">
                {" "}
                User Name: {row.user.name}
              </Typography>
              <Typography color="textPrimary">
                {" "}
                Device Name: {row.device.name}
              </Typography>
              <Typography color="textPrimary">
                {" "}
                Channel: {row.channel}
              </Typography>
              <Typography color="textPrimary">
                {" "}
                Event: {row.eventType === "START" ? "INHALE" : row.eventType}
              </Typography>
              <Typography color="textPrimary">
                {" "}
                Duration:{" "}
                {Math.floor(
                  moment.duration(Number(row.duration) * 1000).asSeconds()
                )}{" "}
                Sec
              </Typography>
            </CardContent>
          </Card>
        ))}
      </div>
    );
  }

  return (
    <TableContainer component={Paper}>
      <Table
        aria-label="a dense table"
        sx={{ minWidth: 400 }}
        className={classes.table}
      >
        <TableHead>
          <TableRow>
            <TableCell>
              <b>Date</b>
            </TableCell>
            <TableCell align="center">
              <b>User Name</b>
            </TableCell>
            <TableCell align="center">
              <b>Device Name</b>
            </TableCell>
            <TableCell align="center">
              <b>Channel</b>
            </TableCell>
            <TableCell align="center">
              <b>Event</b>
            </TableCell>
            <TableCell align="center">
              <b>Duration&nbsp;(Sec)</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {logs.map((row) => (
            <TableRow
              key={row._id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {moment.unix(row.timeStamp).format("lll")}
              </TableCell>
              <TableCell align="center">{row.user.name}</TableCell>
              <TableCell align="center">{row.device.name}</TableCell>
              <TableCell align="center">{row.channel}</TableCell>
              <TableCell align="center">
                {row.eventType === "START" ? "INHALE" : row.eventType}
              </TableCell>
              <TableCell align="center">
                {Math.floor(
                  moment.duration(Number(row.duration) * 1000).asSeconds()
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default withWidth()(withStyles(styles)(LogDataTable));
