import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
} from "recharts";
import { useTheme } from "@mui/material/styles";
import moment from "moment/moment";

export default function LogDataGraph(props) {
  const [graphData, setGraphData] = useState([]);
  const theme = useTheme();

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const getDataLogByFilter = (startDate, endDate, graphData) => {
    const result = [];
    for (
      let date = new Date(startDate);
      date <= new Date(endDate);
      date.setDate(date.getDate() + 1)
    ) {
      const formattedDate = formatDate(date);
      const events = {
        date: formattedDate,
        START: 0,
        RECHARGE: 0,
      };
      result.push(events);
      for (let i = 0; i < graphData.length; i++) {
        if (graphData[i].date === formattedDate) {
          events[graphData[i].eventType] = graphData[i].total;
        }
      }
    }
    const newArray = result.map((obj) => {
      const newObj = {};
      newObj["Inhale"] = Math.floor(Number(obj.START) / 60); // convert START to minutes
      newObj["Recharge"] = Math.floor(Number(obj.RECHARGE) / 60); // convert RECHARGE to minutes
      newObj["date"] = obj.date;
      return newObj;
    });

    setGraphData(newArray);
  };

  useEffect(() => {
    let startDate = props.startDate;
    let endDate = props.endDate;
    if (props.graphData.length > 0) {
      getDataLogByFilter(startDate, endDate, props.graphData);
    } else {
      let blankData = [];
      while (startDate <= endDate) {
        blankData.push({
          date: moment(startDate).format("YYYY-MM-DD"),
          Inhale: 0,
          Recharge: 0,
        });
        startDate = moment(startDate).add(1, "days").format();
      }
      setGraphData(blankData);
    }
  }, [...Object.values(props)]);

  return (
    <React.Fragment>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width="auto"
          height={300}
          data={graphData}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date">
            <Label
              angle={0}
              position="left"
              style={{
                // textAnchor: "middle",
                fill: theme.palette.text.primary,
                ...theme.typography.body1,
              }}
            >
              Date
            </Label>
          </XAxis>
          <YAxis
            stroke={theme.palette.text.secondary}
            style={theme.typography.body2}
          >
            <Label
              angle={270}
              position="left"
              style={{
                textAnchor: "middle",
                fill: theme.palette.text.primary,
                ...theme.typography.body1,
              }}
            >
              Duration (Min)
            </Label>
          </YAxis>
          <Tooltip />
          <Legend />

          <Bar dataKey="Recharge" stackId="a" fill="#8884d8" />
          <Bar dataKey="Inhale" stackId="a" fill="#82ca9d" />
        </BarChart>
      </ResponsiveContainer>
    </React.Fragment>
  );
}
