import { combineReducers } from "redux";
import { userReducer } from "./userReducer";
import { tokenReducer } from "./tokenReducer";
import { companyReducer } from "./companyReducer";

const reducers = combineReducers({
  user: userReducer,
  token: tokenReducer,
  company: companyReducer,
});

export default reducers;
